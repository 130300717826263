import { useContext } from "react";
import { StoreContext } from "shared/context/store-context";

export function GetPhoneNumberofStudent(studentId) {
  const store = useContext(StoreContext);
  try {
    const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());
    if (foundRelation) {
      return store.users.find((user) => user.id.toString() === foundRelation.parent.toString())?.phonenumber || null;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export function GetParentFromStudent(userMap, studentToParentMap, studentId) {
  const parentIds = studentToParentMap[studentId.toString()];
  if (parentIds?.length) {
    return userMap[parentIds[0]];
  }
  return null;
}

export function findUserInServices(services, userId) {
  return services.find((service) => service.users?.some((user) => user.id === userId));
}

export function getContractYearLabel(year) {
  if (year.toString() === "625c94645810394434e7ed79") {
    return "2022-2023";
  } else if (year.toString() === "647d789024676b00145f0d30") {
    return "2023-2024";
  } else if (year.toString() === "661c8d1e862b3f2c307b1276") {
    return "2024-2025";
  } else if (year.toString() === "67855ef173a65566bc06f195") {
    return "2025-2026";
  }
  return "";
}

// export function GetParentFromStudent(studentId) {
//   const store = useContext(StoreContext);
//   try {
//     const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());
//     if (foundRelation) {
//       return store.users.find((user) => user.id.toString() === foundRelation.parent.toString()) || null;
//     } else {
//       return null;
//     }
//   } catch (err) {
//     return null;
//   }
// }
