import React, { useEffect, useContext, useCallback } from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Tab from "@mui/material/Tab";
// import TabPanel from "shared/components/UIElements/TabPanel";
// import PropTypes from "prop-types";
// import Tabs from "components/shared/Tabs";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import { CheckAuthorization } from "shared/util/util";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { useTranslation } from "react-i18next";

// import "./EmployeeItem.css";

import Contracts from "components/accounting/Contracts/Contracts";
import ContractItem from "components/accounting/Contracts/ContractItem";
import Receipts from "components/accounting/Receipts/Receipts";
import Withdraws from "components/accounting/Withdraws/Withdraws";
// import ReceiptItem from "components/accounting/Receipts/ReceiptItem";

import ServiceTemplate from "components/accounting/ServiceTemplate";
import EducationYear from "components/accounting/EducationYear";
import Discount from "components/accounting/Discount";

import Withdraw from "components/accounting/Withdraw";
import Invoice from "components/accounting/Invoice";
import Expense from "components/accounting/Expense";
import Report from "components/accounting/Report";
import VehiclePayment from "components/accounting/VehiclePayment";

import Tabs from "components/shared/Tabs";

const Accounting = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [loaded, setLoaded] = React.useState(false);
  const currentUserId = auth.userId;
  const {
    InsertContract,
    InsertReceipt,
    RetrieveContracts,
    RetrieveReceipts,
    RetrieveWithdraws,
    RetrieveExpenses,
    RetrieveAccountingUtils,
  } = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!auth.permissions.accessToAccounting) {
    return <div className="center">Transfer uygulaması erişiminiz bulunmuyor.</div>;
  }

  let accountingTabs = [];

  accountingTabs.push({
    label: t("contracts"),
    content: <Contracts />,
  });

  accountingTabs.push({
    label: t("newContract"),
    content: <ContractItem isNewItem={true} InsertItem={InsertContract} />,
  });

  accountingTabs.push({
    label: t("receipts"),
    content: <Receipts />,
  });

  // accountingTabs.push({
  //   label: t("Yeni Tahsilat"),
  //   content: <ReceiptItem isNewItem={true} InsertItem={InsertReceipt} />,
  // });

  // accountingTabs.push({
  //   label: t("invoices"),
  //   content: <Invoice />,
  // });

  accountingTabs.push({
    label: t("refunds"),
    content: <Withdraws />,
  });

  // accountingTabs.push({
  //   label: t("vehicleCompensations"),
  //   content: <VehiclePayment />,
  // });

  // accountingTabs.push({
  //   label: t("reports"),
  //   content: <Report />,
  // });

  // accountingTabs.push({
  //   label: t("expenses"),
  //   content: <Expense />,
  // });

  accountingTabs.push({
    label: t("contractTemplates"),
    content: <ServiceTemplate />,
  });

  accountingTabs.push({
    label: t("discounts"),
    content: <Discount />,
  });

  accountingTabs.push({
    label: t("servicePeriods"),
    content: <EducationYear />,
  });

  return (
    <React.Fragment>
      <Tabs tabs={accountingTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />
    </React.Fragment>
  );
};

export default Accounting;
