import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ContractTab from "./ContractItem";
import ContractReceiptsTab from "./ContractReceipts";

import { StoreContext } from "shared/context/store-context";
import Tabs from "components/shared/Tabs";

const ContractAnalyzer = ({ isNewItem, contract, onClose }) => {
  const { t } = useTranslation();
  const { contracts, UpdateContract, InsertContract, DeleteContract, InsertReceipt, UpdateReceipt, DeleteReceipt } =
    useContext(StoreContext);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const contractAnalyzerTabs = React.useMemo(() => {
    const contractItemTab = (
      <ContractTab
        contract={contract}
        isNewItem={isNewItem}
        InsertItem={InsertContract}
        UpdateItem={UpdateContract}
        DeleteItem={DeleteContract}
        onClose={onClose}
      ></ContractTab>
    );

    const contractReceiptsTab = (
      <ContractReceiptsTab
        contract={contract}
        InsertItem={InsertReceipt}
        UpdateItem={UpdateReceipt}
        DeleteItem={DeleteReceipt}
      ></ContractReceiptsTab>
    );

    return [
      {
        label: "Sözleşme Bilgileri",
        content: contractItemTab,
      },
      {
        label: "Sözleşme Tahsilatları",
        content: contractReceiptsTab,
      },
    ];
  }, [
    DeleteContract,
    DeleteReceipt,
    InsertContract,
    InsertReceipt,
    UpdateContract,
    UpdateReceipt,
    contract,
    isNewItem,
    onClose,
  ]);

  return (
    <React.Fragment>
      <Tabs tabs={contractAnalyzerTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={false} />
    </React.Fragment>
  );
};

export default ContractAnalyzer;
