import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";
import { SERVICE_TYPES } from "shared/util/constants";
import { getColumnValue } from "shared/util/datagrid";
import { transfertypes, transferStatus } from "shared/util/types";
import "./Admin.css";

import TransferItem from "./TransferItem";
import ManagedTransfer from "./ManagedTransfer";
import { PERMISSION_TYPES } from "shared/util/constants";

const Transfers = ({ active }) => {
  const { t } = useTranslation();
  const itemType = "transfer";
  const listName = "main_transfers";

  const {
    transfers,
    schoolOptions,
    serviceType,
    standartPersonelServices,
    DeleteTransfer,
    InsertTransfer,
    UpdateTransfer,
  } = useContext(StoreContext);

  const pageItems = active ? transfers.filter((transfer) => transfer?.done === false) : transfers;

  const listColumns = [
    {
      field: "school",
      headerName: "Firma",
      width: 200,
      valueGetter: (params) => {
        try {
          return schoolOptions.find((school) => school.value === params.row.school).label;
        } catch {
          return "";
        }
      },
    },
    {
      field: "transferdate",
      headerName: "Tarih",
      width: 100,
    },
    { field: "name", headerName: "İsim", width: 300 },
    {
      field: "transfertime",
      headerName: "Havaalanı",
      width: 100,
      valueGetter: (params) => {
        try {
          return params.row.transfertime.split(" ")[4].substring(0, 5);
        } catch {
          return "";
        }
      },
    },
    {
      field: "type",
      headerName: "Türü",
      width: 200,
      valueGetter: (params) => transfertypes.find((item) => item.value === params.row.type).label,
    },
    {
      field: "done",
      headerName: "Durum",
      width: 150,
      valueGetter: (params) => transferStatus.find((item) => item.value === params.row.done).label,
      cellClassName: (params) => (params.row.done ? "processdone" : "processongoing"),
    },
  ];
  return (
    <Layout
      hideCreateNewItem={true}
      isAlwaysFullScreen={true}
      hideRightHeader={true}
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={ManagedTransfer}
      NewItemComponent={ManagedTransfer}
      DeleteItem={DeleteTransfer}
      InsertItem={InsertTransfer}
      UpdateItem={UpdateTransfer}
    />
  );
};

export default Transfers;
