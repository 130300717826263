import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Layout from "./shared/Layout";
import ServiceList from "../components/personel-service/PersonelServicesList";
import PersonelServiceItem from "../components/personel-service/PersonelServiceItem";
import NewServiceItem from "../components/personel-service/NewPersonelServiceItem";
import { StoreContext } from "shared/context/store-context";
import { vehicleroutetypes } from "shared/util/types";
import { Delete } from "@mui/icons-material";

const StandardPersonelServices = () => {
  const { t } = useTranslation();
  const itemType = "standartpersonelservice";
  const listName = "main_standartpersonelservices";

  const {
    standartPersonelServices,
    schoolOptions,
    driverOptions,
    InsertPersonelService,
    DeletePersonelService,
    UpdatePersonelService,
  } = useContext(StoreContext);

  const pageItems = standartPersonelServices;

  const listColumns = [
    {
      field: "school",
      headerName: "Kurum",
      width: 200,
      valueGetter: (params) =>
        schoolOptions.find((item) => item?.value?.toString() === params?.row?.school?.id?.toString())?.label,
    },
    { field: "name", headerName: "İsim", width: 300 },
    {
      field: "type",
      headerName: "Türü",
      width: 100,
      valueGetter: (params) =>
        vehicleroutetypes.find((item) => item?.value?.toString() == params.row.type.toString())?.label,
    },
    {
      field: "drivers",
      headerName: "Ana Sürücü",
      width: 300,
      valueGetter: (params) => {
        try {
          return driverOptions.find((item) => item.value.toString() === params?.row?.drivers[0]?.id.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },
    { field: "plate", headerName: "Plaka", width: 100 },
    {
      field: "numOfPassengers",
      headerName: t("numberofpassengers"),
      width: 200,
      valueGetter: (params) => params.row?.users?.length?.toString() || "-",
    },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={PersonelServiceItem}
      NewItemComponent={PersonelServiceItem}
      DeleteItem={DeletePersonelService}
      InsertItem={InsertPersonelService}
      UpdateItem={UpdatePersonelService}
      isAlwaysFullScreen={true}
    />
  );
};

export default StandardPersonelServices;
