import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import NotFound from "shared/components/UIElements/NotFound";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";

// #region Utils

import { notificationtypes, notificationmethods, durations, yesNoOptions, fileTypeOptions } from "shared/util/types";

import { VALIDATOR_MAX, VALIDATOR_MIN, VALIDATOR_NONE } from "shared/util/validators";
import { SERVICE_TYPES } from "shared/util/constants";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const RelationItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { schoolOptions, serviceType, stopOptions, parentOptions, studentOptions, schools, automations } =
    useContext(StoreContext);
  const { schoolType } = useContext(AuthContext);

  // item info
  const itemType = "automation";
  const endPointUpdate = "automations";
  const endPointDelete = "automations";
  const endPointCreate = "automations";

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      algorithmId: { value: "", isValid: true },
      text: { value: "", isValid: true },
      fileTypes: { value: [], isValid: true },
      isTransferUpload: { value: 1, isValid: true },
      schools: { value: [], isValid: true },
      exampleFileName: { value: "", isValid: true },
    },
    true
  );

  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentAutomation = automations.find((automation) => automation.id === itemId);
      setLoadedItem(currentAutomation);
    } catch (err) {}
    setIsItemLoaded(true);
  }, [automations, itemId]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    const infoToBeSent = {
      name: formState.inputs.name.value,
      algorithmId: formState.inputs.algorithmId.value,
      text: formState.inputs.text.value,
      fileTypes: formState.inputs.fileTypes.value,
      isTransferUpload: formState.inputs.isTransferUpload.value,
      schools: formState.inputs.schools.value,
      exampleFileName: formState.inputs.exampleFileName.value,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <Input
            id="name"
            element="input"
            type="text"
            label="name"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.name || ""}
            initialValid={true}
            onInput={inputHandler}
            errorText="nameErrorText"
          />

          <Input
            id="algorithmId"
            element="input"
            type="text"
            label="algorithmId"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.algorithmId || ""}
            initialValid={true}
            onInput={inputHandler}
            errorText="algorithmIdErrorText"
          />

          <Input
            id="exampleFileName"
            element="input"
            type="text"
            label="exampleFileName"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.exampleFileName || ""}
            initialValid={true}
            onInput={inputHandler}
            errorText="exampleFileNameErrorText"
          />

          <Input
            id="text"
            element="input"
            type="text"
            label="text"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.text || ""}
            initialValid={true}
            onInput={inputHandler}
            errorText="textErrorText"
          />

          <CustomSelect
            options={fileTypeOptions}
            id="fileTypes"
            onInput={inputHandler}
            label="fileTypes"
            isMulti={true}
            initialValid={true}
            initialValue={
              loadedItem?.fileTypes?.length
                ? fileTypeOptions.filter((fileTypeOption) => loadedItem?.fileTypes?.includes(fileTypeOption.value))
                : []
            }
          ></CustomSelect>

          <CustomSelect
            options={yesNoOptions}
            id="isTransferUpload"
            onInput={inputHandler}
            label="isTransferUpload"
            initialValid={true}
            initialValue={
              yesNoOptions.find((option) => loadedItem?.isTransferUpload.toString() === option.value.toString()) ||
              yesNoOptions[1]
            }
          ></CustomSelect>

          <CustomSelect
            options={schoolOptions}
            id="schools"
            onInput={inputHandler}
            label="schools"
            isMulti={true}
            initialValid={true}
            initialValue={
              loadedItem?.schools?.length
                ? schoolOptions.filter((schoolOption) => loadedItem?.schools?.includes(schoolOption.value))
                : []
            }
          ></CustomSelect>

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.inputs.name.value || !formState.isValid || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default RelationItem;
