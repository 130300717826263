import React, { useState, useContext, useEffect, useRef } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { Oval } from "react-loader-spinner";
import CircularProgress from "@mui/material/CircularProgress";
import MapWithTransferTimesContainer from "components/shared/MapWithTransferTimesContainer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Help from "pages/shared/Help";

import ExcelUpload from "shared/components/FormElements/FileUpload";
import Modal from "shared/components/UIElements/Modal";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";

import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import { AuthContext } from "shared/context/auth-context";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import AreYouSureModal from "components/shared/AreYouSureModal";

import { styled } from "@mui/material/styles";

import "./Admin.css";
import { getStudentsWithLocationProblems } from "./utils";
import { set } from "lodash";
const DistributeStudents = (props) => {
  const { schoolOptions, schools } = useContext(StoreContext);
  const auth = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [showCreationModal, setShowCreationModal] = useState(false);

  const [progressCounter, setProgressCounter] = useState(0);

  const { isLoading, sendRequest } = useHttpClient();
  const [reportCreated, setReportCreated] = useState(false);
  const [hasProblematicLocations, setHasProblematicLocations] = useState(false);
  const processIdRef = useRef(null);
  const [processId, setProcessId] = useState(null);
  const isProcessingRef = useRef(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const isFetchingRef = useRef(false);

  const [problematicStudents, setProblematicStudents] = useState([]);
  const [noResultStudents, setNoResultStudents] = useState([]);
  const [noResultDrivers, setNoResultDrivers] = useState([]);
  const [okStudents, setOkStudents] = useState([]);
  const [result, setResult] = useState(null);
  const [allowServiceCreation, setAllowServiceCreation] = useState(false);
  const [comparisionResults, setComparisionResults] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
      excel: { value: null, isValid: false },
    },
    false
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getResults = async (response) => {
    let noResultStudents_ = [];
    let problematicStudents_ = [];
    let okStudents_ = [];
    let noResultDrivers_ = [];

    try {
      if (response?.data) {
        setResult(response.data);

        for (let i = 0; i < response.data.students.length; i++) {
          const student = response.data.students[i];

          if (!student.student) {
            noResultStudents_.push(student.nameOnExcel);
            continue;
          } else {
            if (student.hasContract && student.hasRelation && student.isActive && student.hasLocations) {
              okStudents_.push(student);
            } else {
              problematicStudents_.push(student);
            }
          }
        }

        for (let i = 0; i < response.data.vehicleRoutes.length; i++) {
          const vehicleRoute = response.data.vehicleRoutes[i];

          if (!vehicleRoute.driver) {
            noResultDrivers_.push(vehicleRoute.driverNameOnExcel);
            continue;
          }
        }

        await compareServices(response.data.vehicleRoutes);

        setProblematicStudents(problematicStudents_);
        setOkStudents(okStudents_);
        setNoResultDrivers(noResultDrivers_);
        setNoResultStudents(noResultStudents_);
        setAllowServiceCreation(
          problematicStudents_.length === 0 && noResultStudents_.length === 0 && noResultDrivers_.length === 0
        );
        setReportCreated(true);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const fetchProcessState = async () => {
      if (!processIdRef.current || !isProcessingRef.current || isFetchingRef.current) {
        return;
      }
      isFetchingRef.current = true;
      try {
        const resp = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/process/${processIdRef.current}`, "GET");

        if (resp?.process?.counter) {
          setProgressCounter(resp.process.counter);
          if (resp.process.counter >= 100) {
            setProgressCounter(0);
          }
        }

        if (resp?.process?.processing === false && resp?.process?.errorCode === 0) {
          getResults(resp.process);
        } else if (resp?.process?.processing === false && resp?.process?.errorCode === 1) {
          setErrorMessage(resp.process.error);
        }
        isProcessingRef.current = resp.process.processing;
        setIsProcessing(resp.process.processing);
      } catch (err) {}
      isFetchingRef.current = false;
    };
    fetchProcessState();

    const intervalId = setInterval(fetchProcessState, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [processId, sendRequest, auth.token, getResults]);

  const createReport = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    const formData = new FormData();
    formData.append("school", formState.inputs.school.value);
    formData.append("excel", formState.inputs.excel.value);

    let distributionProcessId = null;
    setIsProcessing(true);
    setReportCreated(false);

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/automated/schoolexcelanalyzer`,
        "POST",
        formData,
        null,
        false
      );
      distributionProcessId = response.process.id;
    } catch (err) {}

    if (!distributionProcessId) {
      setIsProcessing(false);
      isProcessingRef.current = false;
      processIdRef.current = null;
      setProcessId(null);
      setOpenSnackbar(true);
      return;
    }

    setIsProcessing(true);
    isProcessingRef.current = true;
    setProcessId(distributionProcessId);
    processIdRef.current = distributionProcessId;
  };

  const standardHelpItems = [
    "1. Şu anda erişim yetkinizin olduğu Excel İşlemleri sekmesini görüntülemektesiniz.",
    "2. Sayfada Çetin Tur okul listeleri standart excelini yükleyerek öğrenci bilgilerini kontrol edebilir ve servisleri otomatik olarak yaratabilirsiniz.",
    "3. Okul seçimini ilgili excel dosyasını yükleyeceğiniz okul olarak seçiniz. Farklı bir seçim yapmanız durumunda hatalı sonuçlar görüntülenecektir.",
    "4. Özet olarak excelinizde şu bilgiler olmalıdır. GENEL LİSTE için C: Sabah Araç No, D: Akşam Araç No, F: Öğrenci Adı. ARAÇ BİLGİLERİ B: Araç No, D: Plaka, G: Sürücü Adı Soyadı",
    "5. Lütfen aşağıdaki ayrıntılı bilgilere dikkat ediniz.",
  ];

  const highlightedHelpItems = [
    "1. Excel dosyanızda GENEL LİSTE isimli bir sekme olmalıdır.",
    "2. GENEL LİSTE sekmesinde F sütununda öğrenci adı bilgisi olmalıdır.",
    "3. GENEL LİSTE sekmesinde C sütununda SABAH ARAÇ NO, D sütununda ise AKŞAM ARAÇ NO bilgileri olmalıdır. Bu araç no bilgileri SAYI olmalıdır. 'Öğretmen servisi gibi ibareler işleme alınmaz.'",
    "4. C ya da D sütunları bulunmuyorsa bunları oluşturunuz ve ilgili servis numaralarını giriniz.",
    "5. Eğer bir öğrenci henüz bir servise atanmamışsa ya da servis kullanmayacaksa C ve D sütunlarını boş bırakınız. Bu öğrenciler dikkate alınmayacaktır.",
    "6. Öğrencinin Sabah ve Akşam servisi aynı olsa dahi bu iki sütunu da doldurunuz ve aynı sayıyı giriniz. Aynı değilse gerekli sayıları giriniz.",
    "7. Listedeki satırlarda boşluk bırakmayınız. Yazılım satırları aşağı doğru taramaktadır. Öğrenci ismi kısmında boşluk görülen bir satırda işlem sonlandırılır.",
    "8. Excel dosyanızda ARAÇ BİLGİLERİ isimli bir sekme olmalıdır.",
    "9. ARAÇ BİLGİLERİ sekmesinde B sütununda ARAÇ NO, D sütununda PLAKA, G sütununda SÜRÜCÜ ADI SOYADI bilgisi olmalıdır.",
    "10. Yazılım satırları aşağı doğru taramaktadır. İlk boşluk olan satırda tarama işlemini sonlandırılır.",
  ];

  const createAllServices = async () => {
    const currentSchool = schools.find((school) => school.id === formState.inputs.school.value);

    for (let i = 0; i < result.vehicleRoutes.length; i++) {
      const vehicleRoute = result.vehicleRoutes[i];

      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`;
      const postType = "POST";

      const infoToBeSent = {
        name: currentSchool.tag + " " + vehicleRoute.name,
        type: vehicleRoute.type,
        school: formState.inputs.school.value,
        driver: vehicleRoute.driver._id,
        plate: vehicleRoute.plate,
        starttime: vehicleRoute.type === 100 ? "06:00" : "14:00",
        stoptime: vehicleRoute.type === 100 ? "13:45" : "18:30",
        routeClientArrivalTime: vehicleRoute.type === 100 ? "07:50" : "15:50",
        student: vehicleRoute.students.map((student) => student._id),

        status: 1,
        location: { lat: -1, lng: -1 },
        routelocations: [],
      };

      //standard code
      try {
        const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));
      } catch (err) {
        isFetchingRef.current = false;
      }
    }
  };

  const compareServices = async (vehicleRoutes) => {
    const currentSchool = schools.find((school) => school.id === formState.inputs.school.value);

    const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`);

    const systemVehicleRoutes = responseData.vehicleRoutes;

    let errors = [];

    for (let i = 0; i < vehicleRoutes.length; i++) {
      let serviceName = "";
      let serviceTypeName = "";

      let serviceFound = false;
      let isDriverOk = false;
      let isPlateOk = false;
      let differentStudents = [];

      const vehicleRoute = vehicleRoutes[i];

      const vehicleRouteInSystem = systemVehicleRoutes.find(
        (systemVehicleRoute) =>
          systemVehicleRoute.name === currentSchool?.tag + " " + vehicleRoute.name &&
          systemVehicleRoute.type.toString() === vehicleRoute.type.toString() &&
          systemVehicleRoute.school.id === formState.inputs.school.value
      );

      serviceName = currentSchool.tag + " " + vehicleRoute.name;
      serviceTypeName = vehicleRoute.type.toString() === "100" ? "Sabah" : "Akşam";

      if (!vehicleRouteInSystem) {
        errors.push({
          serviceName: serviceName,
          serviceTypeName: serviceTypeName,
          serviceFound,
          isDriverOk,
          isPlateOk,
          differentStudents,
        });
        continue;
      }

      serviceFound = true;
      isDriverOk = vehicleRoute.driver?._id === vehicleRouteInSystem.driver[0];
      isPlateOk = vehicleRoute.plate === vehicleRouteInSystem.plate;

      for (let j = 0; j < vehicleRoute.students.length; j++) {
        const student = vehicleRoute.students[j];

        const studentIds = vehicleRouteInSystem.student.map((student) => student._id);
        if (!studentIds.includes(student._id)) {
          differentStudents.push(student.name);
        }
      }
      for (let j = 0; j < vehicleRouteInSystem.student.length; j++) {
        const student = vehicleRouteInSystem.student[j];
        const studentIds = vehicleRoute.students.map((student) => student._id);
        if (!studentIds.includes(student._id)) {
          differentStudents.push(student.name);
        }
      }

      errors.push({
        serviceName: serviceName,
        serviceTypeName: serviceTypeName,
        serviceFound,
        isDriverOk,
        isPlateOk,
        differentStudents,
      });
    }

    setComparisionResults(errors);
  };

  return (
    <React.Fragment>
      <AreYouSureModal
        onConfirmed={() => {
          createAllServices();
        }}
        text="Listedeki tüm servisleri yaratmak istediğinize emin misiniz?"
        onClose={() => setShowCreationModal(false)}
        showModal={showCreationModal}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: "100%" }}>
          {errorMessage ? errorMessage : "Uygunsuz dosya yüklediniz. Lütfen tekrar deneyin."}
        </MuiAlert>
      </Snackbar>

      <div className="horizontal-flex" style={{ padding: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <form style={{ margin: "0px", maxWidth: "800px" }} onSubmit={createReport}>
            <CustomSelect
              options={schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              value={formState.inputs.school}
              initialValue={schoolOptions[0]}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  columnGap: "15px",
                  alignItems: "center",
                }}
              >
                <ExcelUpload id="excel" onInput={inputHandler}></ExcelUpload>{" "}
                {formState.inputs.excel.value && (
                  <p style={{ marginTop: "0", marginBottom: "1rem" }}>{formState.inputs.excel.value.name}</p>
                )}
              </div>
              <Button type="submit" size="small" disabled={!formState.isValid || isProcessing}>
                Dosyayı Analiz Et
              </Button>
            </div>
          </form>

          {isProcessing && (
            <div className="horizontal-flex" style={{ justifyContent: "flex-start", marginTop: "20px" }}>
              {/* <Oval visible={true} height="40" width="40" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" /> */}
              <CircularProgress color="secondary" />
              <p>Dosyanız analiz ediliyor...</p>
            </div>
          )}
        </div>
        <Help standardHelpItems={standardHelpItems} highlightedHelpItems={highlightedHelpItems} />
      </div>

      {reportCreated && (
        <div>
          {!allowServiceCreation && (
            <React.Fragment>
              <br />
              <h3 style={{ color: "red" }}>
                {" "}
                Servis oluşturma adımına geçemezsiniz. Lütfen önce hataları giderip işlemi tekrarlayın.
              </h3>
            </React.Fragment>
          )}

          <br />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              {noResultStudents.length > 0 && (
                <div>
                  Excel'de olup da sistemde bulunamayan öğrenciler - <strong> {noResultStudents.length} hata </strong>
                </div>
              )}

              {noResultStudents.length === 0 && (
                <div style={{ display: "flex" }}>
                  <strong style={{ marginRight: "20px" }}>
                    {" "}
                    <ThumbUp />
                  </strong>{" "}
                  Tebrikler. Excel'deki tüm öğrenciler sistemde bulundu.
                </div>
              )}
            </AccordionSummary>
            {noResultStudents.length > 0 && (
              <AccordionDetails display="flex">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableBody>
                      {noResultStudents.map((name) => (
                        <TableRow key={name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <strong> {name}</strong>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            )}
          </Accordion>

          <br />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              {noResultDrivers.length > 0 && (
                <div>
                  Excel'de olup da sistemde bulunamayan sürücüler - <strong> {noResultDrivers.length} hata </strong>
                </div>
              )}

              {noResultDrivers.length === 0 && (
                <div style={{ display: "flex" }}>
                  <strong style={{ marginRight: "20px" }}>
                    {" "}
                    <ThumbUp />
                  </strong>{" "}
                  Tebrikler. Excel'deki tüm sürücüler sistemde bulundu.
                </div>
              )}
            </AccordionSummary>
            {noResultDrivers.length > 0 && (
              <AccordionDetails display="flex">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableBody>
                      {noResultDrivers.map((name) => (
                        <TableRow key={name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            <strong> {name}</strong>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            )}
          </Accordion>

          <br />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              {problematicStudents.length > 0 && (
                <div>
                  Sistemde hatalı verisi bulunan öğrenciler - <strong> {problematicStudents.length} hata </strong>
                </div>
              )}

              {problematicStudents.length === 0 && (
                <div style={{ display: "flex" }}>
                  <strong style={{ marginRight: "20px" }}>
                    {" "}
                    <ThumbUp />
                  </strong>{" "}
                  Tebrikler. Excel'deki tüm öğrencilerin verileri sistemde eksiksiz bulundu.
                </div>
              )}
            </AccordionSummary>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableBody>
                  {problematicStudents.map((row) => (
                    <TableRow key={row.nameOnExcel} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.nameOnExcel}
                      </TableCell>
                      {row.student && (
                        <React.Fragment>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={!row.hasContract ? { backgroundColor: "red", color: "white" } : null}
                          >
                            Sözleşme: {row.hasContract ? " OK" : "HATA"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={!row.hasRelation ? { backgroundColor: "red", color: "white" } : null}
                          >
                            Bildirim: {row.hasRelation ? " OK" : "HATA"}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={!row.isActive ? { backgroundColor: "red", color: "white" } : null}
                          >
                            Durum: {row.isActive ? " AKTİF" : "HATA"}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={!row.hasLocations ? { backgroundColor: "red", color: "white" } : null}
                          >
                            Konum İşaretlemeleri: {row.hasLocations ? " OK" : "HATA"}
                          </TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>

          <br />

          {comparisionResults.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                Excel ve Sistem arasındaki servis bilgileri karşılaştırması
              </AccordionSummary>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="simple table">
                  <TableBody>
                    {comparisionResults.map((comparisionResult) => (
                      <TableRow key={comparisionResult.serviceName + comparisionResult.serviceTypeName}>
                        <TableCell component="th" scope="row">
                          <strong>{comparisionResult.serviceName + " - " + comparisionResult.serviceTypeName}</strong>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={!comparisionResult.serviceFound ? { backgroundColor: "red", color: "white" } : null}
                        >
                          <strong>{"Sistem Kaydı: " + (comparisionResult.serviceFound ? "OK" : "HATA")}</strong>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={!comparisionResult.isDriverOk ? { backgroundColor: "red", color: "white" } : null}
                        >
                          <strong>{"Sürücü: " + (comparisionResult?.isDriverOk ? "OK" : "HATA")}</strong>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={!comparisionResult.isPlateOk ? { backgroundColor: "red", color: "white" } : null}
                        >
                          <strong>{"Plaka: " + (comparisionResult?.isPlateOk ? "OK" : "HATA")}</strong>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={
                            comparisionResult.differentStudents.length
                              ? { backgroundColor: "red", color: "white" }
                              : null
                          }
                        >
                          <strong>
                            Farklı Öğrenciler:{" "}
                            {comparisionResult.differentStudents.length
                              ? comparisionResult.differentStudents.map((studentName, index) => (
                                  <p key={index}>
                                    {index + 1}. {studentName}
                                  </p>
                                ))
                              : "YOK"}
                          </strong>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Accordion>
          )}

          {(allowServiceCreation || true) && (
            <React.Fragment>
              <br />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  {result.vehicleRoutes.length > 0 && (
                    <div>
                      Servisler - <strong> {result.vehicleRoutes.length} </strong>
                    </div>
                  )}

                  {!result?.vehicleRoutes?.length && (
                    <div style={{ display: "flex" }}>
                      <strong style={{ marginRight: "20px" }}>
                        {" "}
                        <ThumbDown />
                      </strong>{" "}
                      Servis bilgileri bulunamadı.
                    </div>
                  )}
                </AccordionSummary>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <h4>Servis Adı</h4>
                        </TableCell>
                        <TableCell>
                          <h4>Sürücü</h4>
                        </TableCell>
                        <TableCell>
                          <h4>Plaka</h4>
                        </TableCell>
                        <TableCell>
                          <h4>Servis Tipi</h4>
                        </TableCell>
                        <TableCell>
                          <h4>Öğrenci Sayısı</h4>
                        </TableCell>
                        <TableCell>
                          <h4>Öğrenciler</h4>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {result.vehicleRoutes.map((vehicleRoute) => (
                        <React.Fragment>
                          <TableRow key={vehicleRoute.name + vehicleRoute.type}>
                            <TableCell component="th" scope="row">
                              <strong>{vehicleRoute.name}</strong>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <strong>{vehicleRoute?.driver?.name || "HATA"}</strong>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <strong>{vehicleRoute.plate}</strong>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <strong>{vehicleRoute.type.toString() === "100" ? "Sabah" : "Akşam"}</strong>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <strong>{vehicleRoute.students.map((student) => student.name).length}</strong>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: "700px" }}>
                              <strong>
                                {vehicleRoute.students.map((student, index) => (
                                  <p>
                                    {" "}
                                    {index + 1}. {student.name}
                                  </p>
                                ))}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Accordion>

              <br />
              <div style={{ paddingLeft: "16px" }}>
                <Button onClick={() => setShowCreationModal(true)}>Aşağıdaki tüm servisleri yarat</Button>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default DistributeStudents;
