import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { StoreContext } from "shared/context/store-context";

import ItemHeader from "pages/shared/ItemHeader";
import { days, days_en } from "shared/util/types";

const TransferInfo = ({ transfer, onClose }) => {
  const { t } = useTranslation();
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  const { schoolOptions } = useContext(StoreContext);

  let leftTitle = schoolOptions.find((option) => option.value === transfer.school.toString()).label;
  if (leftTitle.toLowerCase().includes("freebird")) {
    leftTitle = "Freebird Uçuş Transferi";
  }

  return (
    <React.Fragment>
      {transfer && (
        <div
          className="card horizontal-flex align-items-center justify-content-between"
          style={{ borderRadius: "0px", width: "100%" }}
        >
          <div className="horizontal-flex align-items-center justify-content-start" style={{ gap: "40px", flex: 10 }}>
            <h4 style={{ margin: "0px" }}>{leftTitle}</h4>

            <span>
              <strong>Rota Tipi:</strong> {transfer.type.toString() === "100" ? "Toplama" : "Dağıtma"}
            </span>

            <span>
              <strong>Tarih:</strong> {transfer.transferdate}
            </span>

            <span>
              <strong>Uçuş:</strong> {transfer.name}
            </span>

            <span>
              <strong>
                {transfer.type.toString() === "100" ? "Uçuş Kalkışı Öncesi Varış:" : "Uçuş İnişi Sonrası Varış:"}{" "}
              </strong>{" "}
              {transfer.transfertime.split(" ")[4].substring(0, 5)}
            </span>
          </div>
          <div style={{ width: "100%", justifyContent: "flex-end", display: "flex", flex: 1 }}>
            <ItemHeader isAlwaysFullScreen={true} onClose={onClose} onFullScreen={() => {}} onHalfScreen={() => {}} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TransferInfo;
