import { useBackendContext } from "./backend-hook";

export const useShiftBackend = () => {
  const { sendRequest, t } = useBackendContext();

  const updateUsersInTheShift = async (shiftId, users) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/shifts/${shiftId}`,
        "PATCH",
        JSON.stringify({ users })
      );
      return responseData.shift || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return null;
  };

  const updateUsersInTheService = async (serviceId, users) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`,
        "PATCH",
        JSON.stringify({ users })
      );
      return responseData.personelservice || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return null;
  };

  const updateUsersDetailsInTheService = async (serviceId, usersdetails) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`,
        "PATCH",
        JSON.stringify({ usersdetails })
      );
      return responseData.personelservice || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return null;
  };

  const addService = async (service) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices`,
        "POST",
        JSON.stringify(service)
      );
      return responseData.personelservice || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return null;
  };

  const deleteService = async (serviceId) => {
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`, "DELETE", null);
      return true;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return false;
  };

  const updateVehicleRoutesInTheTransfer = async (transferId, vehicleRoutes) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/transfers/${transferId}`,
        "PATCH",
        JSON.stringify({ vehicleRoutes })
      );
      return responseData.transfer || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return null;
  };

  return {
    updateUsersInTheShift,
    updateUsersInTheService,
    updateUsersDetailsInTheService,
    addService,
    deleteService,
    updateVehicleRoutesInTheTransfer,
  };
};
