import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Dragger from "./dragger/Dragger";
import TransferDragger from "components/service-analyzer/tabs/dragger/TransferDragger";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import Help from "pages/shared/Help";

// #region Components
import FileUpload from "shared/components/FormElements/FileUpload";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import Grid from "@mui/material/Grid";
// #endregion Hooks

import "./Item.css";

const TransferNewBulk = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const { schoolOptions, schools, automations, automationOptions, users } = useContext(StoreContext);
  const [haveResponse, setHaveResponse] = useState(false);

  const [transferCreated, setTransferCreated] = useState(false);
  const [isTransferCreating, setIsTransferCreating] = useState(false);
  const [submitAllowed, setSubmitAllowed] = useState(false);

  const [finishedTransfers, setFinishedTransfers] = useState([]);
  const isFetchingRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [planes, setPlanes] = useState([]);
  const [transfers, setTransfers] = useState([]);
  //const [flights, setFlights] = useState([]);
  const [personelServices, setPersonelServices] = useState([]);
  const [analysingServices, setAnalysingServices] = useState([]);
  const [distances, setDistances] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [processId, setProcessId] = useState(null);
  const isProcessingRef = useRef(false);
  const processIdRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const alertMessageRef = useRef("");

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const standardHelpItems = [
    "1. Şu anda erişim yetkinizin olduğu Excel İşlemleri sekmesini görüntülemektesiniz.",
    "2. Sayfada Çetin Tur okul listeleri standart excelini yükleyerek öğrenci bilgilerini kontrol edebilir ve servisleri otomatik olarak yaratabilirsiniz.",
    "3. Okul seçimini ilgili excel dosyasını yükleyeceğiniz okul olarak seçiniz. Farklı bir seçim yapmanız durumunda hatalı sonuçlar görüntülenecektir.",
    "4. Özet olarak excelinizde şu bilgiler olmalıdır. GENEL LİSTE için C: Sabah Araç No, D: Akşam Araç No, F: Öğrenci Adı. ARAÇ BİLGİLERİ B: Araç No, D: Plaka, G: Sürücü Adı Soyadı",
    "5. Lütfen aşağıdaki ayrıntılı bilgilere dikkat ediniz.",
  ];

  const highlightedHelpItems = [
    "1. Excel dosyanızda GENEL LİSTE isimli bir sekme olmalıdır.",
    "2. GENEL LİSTE sekmesinde F sütununda öğrenci adı bilgisi olmalıdır.",
    "3. GENEL LİSTE sekmesinde C sütununda SABAH ARAÇ NO, D sütununda ise AKŞAM ARAÇ NO bilgileri olmalıdır. Bu araç no bilgileri SAYI olmalıdır. 'Öğretmen servisi gibi ibareler işleme alınmaz.'",
    "4. C ya da D sütunları bulunmuyorsa bunları oluşturunuz ve ilgili servis numaralarını giriniz.",
    "5. Eğer bir öğrenci henüz bir servise atanmamışsa ya da servis kullanmayacaksa C ve D sütunlarını boş bırakınız. Bu öğrenciler dikkate alınmayacaktır.",
    "6. Öğrencinin Sabah ve Akşam servisi aynı olsa dahi bu iki sütunu da doldurunuz ve aynı sayıyı giriniz. Aynı değilse gerekli sayıları giriniz.",
    "7. Listedeki satırlarda boşluk bırakmayınız. Yazılım satırları aşağı doğru taramaktadır. Öğrenci ismi kısmında boşluk görülen bir satırda işlem sonlandırılır.",
    "8. Excel dosyanızda ARAÇ BİLGİLERİ isimli bir sekme olmalıdır.",
    "9. ARAÇ BİLGİLERİ sekmesinde B sütununda ARAÇ NO, D sütununda PLAKA, G sütununda SÜRÜCÜ ADI SOYADI bilgisi olmalıdır.",
    "10. Yazılım satırları aşağı doğru taramaktadır. İlk boşluk olan satırda tarama işlemini sonlandırılır.",
  ];

  useEffect(() => {
    const fetchProcessState = async () => {
      if (!processIdRef.current || !isProcessingRef.current || isFetchingRef.current) {
        return;
      }
      isFetchingRef.current = true;
      try {
        const resp = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/process/${processIdRef.current}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (resp?.process?.processing === false && resp?.process?.errorCode === 0) {
          await getFlightResults(resp.process);
        } else if (resp?.process?.processing === false && resp?.process?.errorCode === 1) {
          setErrorMessage(t("httpCodes." + resp?.process?.error));
        }
        isProcessingRef.current = resp.process.processing;
        setIsProcessing(resp.process.processing);
      } catch (err) {}
      isFetchingRef.current = false;
    };
    fetchProcessState();

    const intervalId = setInterval(fetchProcessState, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [processId, sendRequest, auth.token, t]);

  const getFlightResults = async (response) => {
    try {
      if (response?.data.length) {
        //setFlights(response.data);

        let checkSubmitAllowed = true;
        // response.data.transfers.forEach((transfer) => {
        //   const nullPerson = transfer.people.find((person) => person.id === null);
        //   if (nullPerson) {
        //     checkSubmitAllowed = false;
        //   }
        // });

        let currentTransfers = [];

        let createdServices = [];
        let createdDistances = [];
        let createdTransfers = [];
        for (let i = 0; i < response.data.length; i++) {
          debugger;
          const currentFlight = response.data[i];

          debugger;
          const transferdate = currentFlight.date;
          const transfertime = currentFlight.transfertime;
          const cabintransfertime = currentFlight.cabintransfertime;
          const cockpittransfertime = currentFlight.cockpittransfertime;
          const strings_transferdate_year = transferdate.split(".")[2];
          const strings_transferdate_month = transferdate.split(".")[1];
          const strings_transferdate_day = transferdate.split(".")[0];

          // const cabinRoute = {};
          // cabinRoute.driver = "63299827ef13360016a66e81";
          // cabinRoute.name =
          //   strings_transferdate_day + "." + strings_transferdate_month + "-" + transfertime + " --- " + (i + 1);
          // cabinRoute.id = Math.random().toString(36).substring(7);
          // cabinRoute.people = currentFlight.cabinUsers;

          // const cockpitRoute = {};
          // cockpitRoute.driver = "63299827ef13360016a66e81";
          // cockpitRoute.name =
          //   strings_transferdate_day + "." + strings_transferdate_month + "-" + transfertime + " --- " + (i + 1);
          // cockpitRoute.id = Math.random().toString(36).substring(7);
          // cockpitRoute.people = currentFlight.cockpitUsers;

          const cabinUsers = (currentFlight.cabinUsers || []).map((user) =>
            user ? { ...user, id: user._id, lat: user.location.lat, lng: user.location.lng } : user
          );

          const cockpitUsers = (currentFlight.cockpitUsers || []).map((user) =>
            user ? { ...user, id: user._id, lat: user.location.lat, lng: user.location.lng } : user
          );

          const cabinRoute = {
            users: cabinUsers,
            type: currentFlight.from.includes("AYT") ? "100" : "200",
            isShift: false,
            school: schools.find(
              (school) =>
                school.id ===
                automations.find((automation) => automation.id === formState.inputs.automationOption.value).schools[0]
            ),
            name:
              strings_transferdate_year +
              "-" +
              strings_transferdate_month +
              "-" +
              strings_transferdate_day +
              " " +
              cabintransfertime +
              " (Kabin)",
            plate: "-",
            routeClientArrivalTime: cabintransfertime,
            starttime: "00:01",
            stoptime: "23:59",
            date: strings_transferdate_year + "-" + strings_transferdate_month + "-" + strings_transferdate_day,
            drivers: ["63299827ef13360016a66e81"],
            id: Math.random().toString(36).substring(7),
          };

          const cockpitRoute = {
            users: cockpitUsers,
            type: currentFlight.from.includes("AYT") ? "100" : "200",
            isShift: false,
            school: schools.find(
              (school) =>
                school.id ===
                automations.find((automation) => automation.id === formState.inputs.automationOption.value).schools[0]
            ),
            name:
              strings_transferdate_year +
              "-" +
              strings_transferdate_month +
              "-" +
              strings_transferdate_day +
              " " +
              cockpittransfertime +
              " (Kokpit)",
            plate: "-",
            routeClientArrivalTime: cockpittransfertime,
            starttime: "00:01",
            stoptime: "23:59",
            date: strings_transferdate_year + "-" + strings_transferdate_month + "-" + strings_transferdate_day,
            drivers: ["63299827ef13360016a66e81"],
            id: Math.random().toString(36).substring(7),
          };

          currentTransfers.push({
            name: currentFlight.flightname,
            users: [...cabinUsers, ...cockpitUsers],
            routes: [cabinRoute, cockpitRoute],
            departuretime: currentFlight.departuretime,
            arrivaltime: currentFlight.arrivaltime,
            transfertime: transfertime,
            cabintransfertime: cabintransfertime,
            cockpittransfertime: cockpittransfertime,
            type: cabinRoute.type,
            date: cabinRoute.date,
            distances: currentFlight.distancesInformation,
          });

          // createdServices.push();

          // createdServices.push();

          //createdDistances.push(...currentFlight.distancesInformation);
        }

        //setAnalysingServices(createdServices);
        //setDistances(createdDistances);
        setTransfers(currentTransfers);
        setSubmitAllowed(checkSubmitAllowed);
        setHaveResponse(true);
      }
      setTransferCreated(false);
    } catch (err) {}
  };

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    const formData = new FormData();

    formData.append("automationId", formState.inputs.automationOption.value);
    formData.append("file", formState.inputs.file.value);

    let processIdChecker = null;
    setIsProcessing(true);

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/automated/fileanalyzer`,
        "POST",
        formData,
        null,
        false
      );
      processIdChecker = response.process.id;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }

    if (!processIdChecker) {
      setIsProcessing(false);
      isProcessingRef.current = false;
      processIdRef.current = null;
      setProcessId(null);
      return;
    }

    setIsProcessing(true);
    isProcessingRef.current = true;
    setProcessId(processIdChecker);
    processIdRef.current = processIdChecker;
  };

  const [formState, inputHandler] = useForm(
    {
      automationOption: { value: automationOptions[0].value, isValid: false },
      file: { value: null, isValid: false },
    },
    false
  );

  const handleTransferCreated = (transferName, transferTime) => {
    debugger;
    setFinishedTransfers([...finishedTransfers, { transferName, transferTime }]);
  };

  const finishedComponent = useMemo(
    () => (transfer) =>
      finishedTransfers.find(
        (finishedTransfer) =>
          finishedTransfer.transferName === transfer.name && finishedTransfer.transferTime === transfer.transfertime
      ) ? (
        <div style={{ display: "inline-flex", color: "green", marginLeft: "10px" }}>
          <b>
            {" "}
            Tamamlandı
            <CheckIcon fontSize="medium" sx={{ verticalAlign: "middle" }} />
          </b>
        </div>
      ) : (
        ""
      ),
    [finishedTransfers] // Recalculate when finishedTransfers changes
  );

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={errorMessage} onClear={clearErrorMessage} />

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      <div className="form-container flex-column">
        <div className="horizontal-flex">
          <form
            style={{ display: "flex", flexDirection: "column", minWidth: "800px", maxWidth: "800px" }}
            onSubmit={itemSubmitHandler}
          >
            <div style={{ flex: 1 }}>
              <CustomSelect
                options={automationOptions}
                id="automationOption"
                onInput={inputHandler}
                label={t("selectProcess")}
                value={formState.inputs.automationOption}
                initialValue={automationOptions[0]}
                validators={[VALIDATOR_REQUIRE()]}
              ></CustomSelect>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}
              >
                <FileUpload
                  id="file"
                  exampleFileName={
                    automations.find((automation) => automation.id === formState.inputs.automationOption.value)
                      ?.exampleFileName || null
                  }
                  disabled={isProcessing}
                  onInput={inputHandler}
                ></FileUpload>
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {formState.inputs.file.value && (
                  <p style={{ marginTop: "0", marginBottom: "0", paddingRight: "20px" }}>
                    ({formState.inputs.file.value.name})
                  </p>
                )}
                <Button type="submit" disabled={!formState.isValid || isProcessing}>
                  {t("submit")}
                </Button>
              </div>
            </div>

            <div>
              <p style={{ fontSize: "14px" }}>
                {automations.find((automation) => automation.id === formState.inputs.automationOption.value)?.text}
              </p>
            </div>
          </form>

          <Help standardHelpItems={standardHelpItems} highlightedHelpItems={highlightedHelpItems} />
        </div>

        {isProcessing && (
          <div
            className="horizontal-flex"
            style={{ justifyContent: "flex-start", marginTop: "50px", alignItems: "center" }}
          >
            {/* <Oval visible={true} height="40" width="40" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" /> */}
            <CircularProgress color="primary" size="30px" />
            <p style={{ margin: "0", fontSize: "24px" }}>{t("fileIsInProgress")}</p>
          </div>
        )}

        {haveResponse && !isProcessing && (
          <div className="new-transfer-item">
            {transfers.map((transfer) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <p>
                    {transfer.type === "100" ? (
                      <div>
                        <b>Tarih: </b> {transfer.date} {"   |   "}
                        <b>Havaalanında Olma:</b> {transfer.cabintransfertime} (Kabin), {transfer.cockpittransfertime}{" "}
                        (Kokpit) {"   |   "}
                        <b>Toplama </b> {"   |   "}
                        <b>Uçuş: </b> {transfer.name} {"   |   "}
                        <b>Kalkış: </b> {transfer.departuretime} {"   |   "}
                        {/* <b>İniş: </b> {plane.planeArrivalTime} {"   |   "} */}
                        {finishedComponent(transfer)}
                      </div>
                    ) : (
                      <div>
                        <b>Tarih: </b> {transfer.date} {"   |   "}
                        <b>Havaalanında Olma:</b> {transfer.transfertime} {"   |   "}
                        <b>Dağıtma </b> {"   |   "}
                        <b>Uçuş: </b> {transfer.name} {"   |   "}
                        {/* <b>Kalkış: </b> {plane.planeDepartureTime} {"   |   "} */}
                        <b>İniş: </b> {transfer.arrivaltime} {"   |   "}
                        <b>{finishedComponent(transfer)}</b>
                      </div>
                    )}
                  </p>
                </AccordionSummary>
                <AccordionDetails display="flex">
                  <DndProvider backend={HTML5Backend}>
                    <TransferDragger
                      school={schools.find(
                        (school) =>
                          school.id ===
                          automations.find((automation) => automation.id === formState.inputs.automationOption.value)
                            .schools[0]
                      )}
                      transferName={transfer.name}
                      cockpittransfertime={transfer.cockpittransfertime}
                      cabintransfertime={transfer.cabintransfertime}
                      type={transfer.type}
                      inputUsers={transfer.users}
                      inputServices={transfer.routes}
                      distances={transfer.distances}
                      onServicesCreated={() => handleTransferCreated(transfer.name, transfer.transfertime)}
                      // transfers={plane.transfers}
                      // onTransferCreated={() => handleTransferCreated(plane.planeName, plane.planeDepartureTime)}
                    />
                  </DndProvider>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TransferNewBulk;
