/*eslint-disable eqeqeq*/
import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import Analysis from "components/analysis/AnalysisManagement";

import Live from "pages/Live";
import LiveItem from "components/live/LiveItem";

import Shifts from "pages/Shifts";
import Stops from "pages/Stops";

import StandardPersonelServices from "pages/StandardPersonelServices";
import ShiftPersonelServices from "pages/ShiftPersonelServices";

import Users from "pages/Users";
import Settings from "pages/Settings";

import Accounting from "pages/Accounting";

import UsersWebsite from "pages/UsersWebsite";

//import Personels from './admin/pages/Personels';
//import NewUserItem from './admin/components/NewUserItem';
import TransferManagement from "pages/TransferManagement";
// import Transfer from "components/employee/Transfer";
// import TransferNew from "components/employee/TransferNew";
// import TransferRegionNew from "components/employee/TransferRegionNew";

import Admin from "pages/Admin";

import CheckAccess from "components/shared/CheckAccess";

import Ikarus from "components/ikarus/IkarusManagement";

// import PLocation from "components/employee/PLocation";
// import PLocationNew from "components/employee/PLocationNew";

import Students from "pages/Students";
import NewStudentItem from "components/student/NewStudentItem";

import Drivers from "pages/Drivers";

import Relations from "pages/Relations";
import NewRelationItem from "components/relation/NewRelationItem";

import Schools from "pages/Schools";
import NewSchoolItem from "components/school/NewSchoolItem";

import Companies from "pages/Companies";
import NewCompanyItem from "components/company/NewCompanyItem";

import VehicleRoutes from "pages/VehicleRoutes";
import NewVehicleRouteItem from "components/vehicle-routes/NewVehicleRouteItem";

import Vehicles from "pages/Vehicles";
import NewVehicleItem from "components/vehicle/NewVehicleItem";

import Login from "pages/auth/Login";
import ForgotPassword from "pages/auth/ForgotPassword";
import RecoverPassword from "pages/auth/RecoverPassword";
import SignUp from "pages/auth/SignUp";
import VerifyEmail from "pages/auth/VerifyEmail";
import NotFound from "pages/auth/NotFound";
import MainNavigation from "navigation/MainNavigation";
import { AuthContext } from "shared/context/auth-context";
import { useAuth } from "shared/hooks/auth-hook";

import { StoreContext } from "shared/context/store-context";
import { useStore } from "shared/hooks/store-hook";
import { useHttpClient } from "shared/hooks/http-hook";

import ShiftManagement from "pages/ShiftManagement";
import { ThemeProvider } from "@mui/material/styles";

import { PERMISSION_TYPES } from "shared/util/constants";

const App = () => {
  const { sendRequest } = useHttpClient();
  window.toast = toast;
  // const store = useContext(StoreContext);
  const {
    token,
    id: clientId,
    tokenExpirationDate,

    name: clientName,
    email: clientEmail,
    serviceType: clientServiceType,
    permissions: clientPermissions,
    type: clientType,
    schools: clientSchools,
    company: clientCompany,

    loaded: clientLoaded,

    theme,

    login,
    logout,
    UpdateTheme,
  } = useAuth();

  let routes;

  const {
    isAdmin,
    users,
    userMap,
    studentToParentMap,
    parentToStudentMap,
    userTypes,
    clientTypes,
    serviceType,
    drivers,
    driverOptions,
    companies,
    relations,
    companyOptions,
    schools,
    vehicles,
    schoolOptions,
    stopOptions,
    studentOptions,
    parentOptions,
    personelOptions,
    vehicleRoutes,
    vehicleRouteOptions,
    students,
    storeReady,
    contracts,
    educationYears,
    discounts,
    serviceTemplates,
    educationYearsOptions,
    selectedEducationYearId,
    discountOptions,
    serviceTemplateOptions,
    receipts,
    isProcessing,
    withdraws,
    contractsOptions,
    expenses,
    vehiclePayments,
    transfers,
    pLocations,
    pLocationsOptions,
    transferregions,
    transferregionOptions,
    ikarusTransfers,
    ikarusTransferRegions,
    ikarusTransferRegionOptions,
    ikarusTransferPassengers,
    ikarusTransferPassengerOptions,
    shifts,
    stops,
    userOptions,
    shiftOptions,
    shiftUsers,
    shiftUserOptions,
    // Personel Services
    standartPersonelServices,
    standartPersonelServiceOptions,
    shiftPersonelServices,
    shiftPersonelServiceOptions,
    InsertUser,
    UpdateUser,
    DeleteUser,
    InsertStudent,
    UpdateStudent,
    DeleteStudent,
    InsertRelation,
    UpdateRelation,
    DeleteRelation,
    InsertShift,
    UpdateShift,
    DeleteShift,
    UpdateShifts,
    InsertStop,
    UpdateStop,
    DeleteStop,
    InsertVehicleRoute,
    UpdateVehicleRoute,
    DeleteVehicleRoute,
    UpdateRelations,
    UpdateVehicles,
    UpdateCompanies,
    UpdateVehicleRoutes,
    UpdateSchools,
    InsertSchool,
    UpdateSchool,
    DeleteSchool,
    UpdateUsers,
    UpdateStudents,
    UpdateEverything,
    RetrieveContracts,
    InsertContract,
    UpdateContract,
    DeleteContract,
    RetrieveReceipts,
    RetrieveWithdraws,
    RetrieveExpenses,
    RetrieveAccountingUtils,
    UpdateVehiclePayments,
    UpdateTransfers,
    UpdatePLocations,
    UpdateTransferregions,
    UpdateIkarusTransfers,
    UpdateIkarusTransferRegions,
    UpdateIkarusTransferPassengers,
    UpdatePersonelServices,
    InsertPersonelService,
    UpdatePersonelService,
    DeletePersonelService,
    UpdateTypes,
    GetParentFromStudent,
    UpdateDrivers,
    InsertDriver,
    UpdateDriver,
    DeleteDriver,
    UpdateSelectedEducationYearId,
    RetrieveEducationYears,
    automations,
    automationOptions,
    InsertAutomation,
    UpdateAutomation,
    DeleteAutomation,
    RetrieveAutomations,
    InsertTransfer,
    UpdateTransfer,
    DeleteTransfer,
    InsertReceipt,
    UpdateReceipt,
    DeleteReceipt,
    InsertWithdraw,
    UpdateWithdraw,
    DeleteWithdraw,
  } = useStore();

  useEffect(() => {
    const UpdateStore = async () => {
      if (clientId != false && token) {
        try {
          await UpdateEverything();
          // const responseData = await sendRequest(
          //   `${process.env.REACT_APP_BACKEND_URL}/clients/${clientId}`,
          //   "GET",
          //   null,
          //   {
          //     "Content-Type": "application/json",
          //     Authorization: "Bearer " + token,
          //   }
          // );

          // const currentClient = responseData.client;
          // if (currentuser.usertype == "1000") {

          // }
          // if (currentuser.usertype == "100" || currentuser.usertype == "200") {
          //   await UpdateEverything(currentuser.school[0].toString(), currentuser.usertype);
          // }
        } catch (err) {
          if (err.code === 401) {
            logout();
          }
        }
      }

      // const currentuser = users.find(user => user.Id == userId);
    };

    UpdateStore();
  }, [UpdateEverything, sendRequest, logout, clientId, token]);

  if (!clientLoaded) {
    return <LoadingSpinner asOverlay />;
  }

  if (clientLoaded && !token) {
    routes = (
      <Routes>
        <Route index element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  if (clientLoaded && token) {
    routes = (
      <Routes>
        <Route element={<CheckAccess />}>
          <Route path="/" element={<Navigate to="/users" />} />
          <Route path="/login" element={<Navigate to="/users" />} />

          {/* <Route path="/plans" element={<Plans />} end /> */}
          <Route path="/settings" element={<Settings />} end />

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_USERS} />}>
            <Route path="/users" element={<Users />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_STUDENTS} />}>
            <Route path="/students" element={<Students />} end />
            <Route path="/new/student" element={<NewStudentItem />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_DRIVERS} />}>
            <Route path="/drivers" element={<Drivers />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_NOTIFICATIONS} />}>
            <Route path="/notifications" element={<Relations />} end />
            <Route path="/new/notification" element={<NewRelationItem />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_VEHICLE_ROUTES} />}>
            <Route path="/vehicleroutes" element={<VehicleRoutes />} end />
            <Route path="/new/vehicleroute" element={<NewVehicleRouteItem />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_TRANSFERS} />}>
            <Route path="/transfermanagement" element={<TransferManagement />} end />
            {/* <Route path="/transfers" element={<Transfer />} end />
            <Route path="/new/transferregion" element={<TransferRegionNew />} end />
            <Route path="/new/transfer" element={<TransferNew />} /> */}
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_IKARUS} />}>
            <Route path="/ikarus" element={<Ikarus />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_LIVE} />}>
            <Route path="/live" element={<Live />} end />
            <Route path="/live/route" element={<LiveItem />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_ACCOUNTING} />}>
            <Route path="/accounting" element={<Accounting />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_ANALYSIS} />}>
            <Route path="/analysis" element={<Analysis />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_STANDARD_PERSONEL_SERVICES} />}>
            <Route path="/standardpersonelservices" element={<StandardPersonelServices />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_SHIFT_PERSONEL_SERVICES} />}>
            <Route path="/shiftpersonelservices" element={<ShiftPersonelServices />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_SHIFTS} />}>
            <Route path="/shifts" element={<Shifts />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_STOPS} />}>
            <Route path="/stops" element={<Stops />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_SHIFT_MANAGEMENT} />}>
            <Route path="/shiftmanagement" element={<ShiftManagement />} end />
          </Route>

          <Route element={<CheckAccess access={PERMISSION_TYPES.ACCESS_TO_ADMIN} />}>
            <Route path="/admin" element={<Admin />} end />
          </Route>

          <Route path="/schools" element={<Schools />} end />
          {/* <Route path="/new/school" element={<NewSchoolItem />} end /> */}

          <Route path="/userswebsite" element={<UsersWebsite />} end />

          {/* <Route path="/plocations" element={<PLocation />} end /> */}
          <Route path="/vehicles" element={<Vehicles />} end />

          {/* <Route path="/new/plocation" element={<PLocationNew />} /> */}
          <Route path="/new/vehicle" element={<NewVehicleItem />} end />

          <Route path="/reset-password" element={<ForgotPassword />} end />
          <Route path="/recover-password" element={<RecoverPassword />} end />

          <Route path="/companies" element={<Companies />} end />
          <Route path="/new/company" element={<NewCompanyItem />} end />

          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: clientLoaded && !!token,
        token: token,
        id: clientId,
        tokenExpirationDate: tokenExpirationDate,

        name: clientName,
        email: clientEmail,
        serviceType: clientServiceType,
        permissions: clientPermissions,
        type: clientType,
        schools: clientSchools,
        company: clientCompany,

        loaded: clientLoaded,
        theme: theme,

        login: login,
        logout: logout,
        UpdateTheme: UpdateTheme,
      }}
    >
      <StoreContext.Provider
        value={{
          isAdmin: isAdmin,
          users: users,
          userMap: userMap,
          studentToParentMap: studentToParentMap,
          parentToStudentMap: parentToStudentMap,
          userTypes: userTypes,
          clientTypes: clientTypes,
          serviceType: serviceType,
          drivers: drivers,
          driverOptions: driverOptions,
          companies: companies,
          companyOptions: companyOptions,
          studentOptions: studentOptions,
          parentOptions: parentOptions,
          personelOptions: personelOptions,
          schools: schools,
          relations: relations,
          vehicles: vehicles,
          students: students,
          schoolOptions: schoolOptions,
          stopOptions: stopOptions,
          vehicleRoutes: vehicleRoutes,
          storeReady: storeReady,
          contracts: contracts,
          educationYears: educationYears,
          discounts: discounts,
          serviceTemplates: serviceTemplates,
          educationYearsOptions: educationYearsOptions,
          selectedEducationYearId: selectedEducationYearId,
          discountOptions: discountOptions,
          serviceTemplateOptions: serviceTemplateOptions,
          receipts: receipts,
          withdraws: withdraws,
          isProcessing: isProcessing,
          contractsOptions: contractsOptions,
          expenses: expenses,
          vehicleRouteOptions: vehicleRouteOptions,
          vehiclePayments: vehiclePayments,
          transfers: transfers,
          pLocations: pLocations,
          pLocationsOptions: pLocationsOptions,
          transferregions: transferregions,
          transferregionOptions: transferregionOptions,
          ikarusTransfers: ikarusTransfers,
          ikarusTransferRegions: ikarusTransferRegions,
          ikarusTransferRegionOptions: ikarusTransferRegionOptions,
          ikarusTransferPassengers: ikarusTransferPassengers,
          ikarusTransferPassengerOptions: ikarusTransferPassengerOptions,
          shifts: shifts,
          stops: stops,
          userOptions: userOptions,
          shiftOptions: shiftOptions,
          shiftUsers: shiftUsers,
          shiftUserOptions: shiftUserOptions,

          // Personel Services
          standartPersonelServices: standartPersonelServices,
          standartPersonelServiceOptions: standartPersonelServiceOptions,
          shiftPersonelServices: shiftPersonelServices,
          shiftPersonelServiceOptions: shiftPersonelServiceOptions,

          InsertUser: InsertUser,
          UpdateUser: UpdateUser,
          DeleteUser: DeleteUser,

          InsertStudent: InsertStudent,
          UpdateStudent: UpdateStudent,
          DeleteStudent: DeleteStudent,

          InsertRelation: InsertRelation,
          UpdateRelation: UpdateRelation,
          DeleteRelation: DeleteRelation,

          InsertShift: InsertShift,
          UpdateShift: UpdateShift,
          DeleteShift: DeleteShift,

          InsertSchool: InsertSchool,
          UpdateSchool: UpdateSchool,
          DeleteSchool: DeleteSchool,

          UpdateShifts: UpdateShifts,

          InsertStop: InsertStop,
          UpdateStop: UpdateStop,
          DeleteStop: DeleteStop,

          InsertVehicleRoute: InsertVehicleRoute,
          UpdateVehicleRoute: UpdateVehicleRoute,
          DeleteVehicleRoute: DeleteVehicleRoute,

          UpdateVehicles: UpdateVehicles,

          UpdateRelations: UpdateRelations,
          UpdateVehicleRoutes: UpdateVehicleRoutes,
          UpdateCompanies: UpdateCompanies,
          UpdateSchools: UpdateSchools,
          UpdateUsers: UpdateUsers,
          UpdateStudents: UpdateStudents,
          UpdateEverything: UpdateEverything,
          RetrieveContracts: RetrieveContracts,
          InsertContract: InsertContract,
          UpdateContract: UpdateContract,
          DeleteContract: DeleteContract,

          RetrieveEducationYears: RetrieveEducationYears,

          RetrieveReceipts: RetrieveReceipts,
          RetrieveWithdraws: RetrieveWithdraws,
          RetrieveExpenses: RetrieveExpenses,
          RetrieveAccountingUtils: RetrieveAccountingUtils,
          UpdateVehiclePayments: UpdateVehiclePayments,
          UpdateTransfers: UpdateTransfers,
          UpdatePLocations: UpdatePLocations,
          UpdateTransferregions: UpdateTransferregions,
          UpdateIkarusTransfers: UpdateIkarusTransfers,
          UpdateIkarusTransferRegions: UpdateIkarusTransferRegions,
          UpdateIkarusTransferPassengers: UpdateIkarusTransferPassengers,
          UpdatePersonelServices: UpdatePersonelServices,
          InsertPersonelService: InsertPersonelService,
          UpdatePersonelService: UpdatePersonelService,
          DeletePersonelService: DeletePersonelService,
          UpdateTypes: UpdateTypes,
          GetParentFromStudent: GetParentFromStudent,
          UpdateDrivers: UpdateDrivers,
          InsertDriver: InsertDriver,
          UpdateDriver: UpdateDriver,
          DeleteDriver: DeleteDriver,
          UpdateSelectedEducationYearId: UpdateSelectedEducationYearId,

          automations: automations,
          automationOptions: automationOptions,
          InsertAutomation: InsertAutomation,
          UpdateAutomation: UpdateAutomation,
          DeleteAutomation: DeleteAutomation,
          RetrieveAutomations: RetrieveAutomations,

          InsertTransfer: InsertTransfer,
          UpdateTransfer: UpdateTransfer,
          DeleteTransfer: DeleteTransfer,

          InsertReceipt: InsertReceipt,
          UpdateReceipt: UpdateReceipt,
          DeleteReceipt: DeleteReceipt,

          InsertWithdraw: InsertWithdraw,
          UpdateWithdraw: UpdateWithdraw,
          DeleteWithdraw: DeleteWithdraw,
        }}
      >
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="bottom-right"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Bounce}
          />
          <main style={{ width: "100%" }}>
            <MainNavigation />
            {routes}
          </main>
        </ThemeProvider>
      </StoreContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
