import { useState, useCallback, useRef, useEffect } from "react";

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}, isApplicationJson = true, isResponseBlob = false) => {
      let sentHeaders = { ...headers };
      if (!headers?.Authorization) {
        const userData = JSON.parse(localStorage.getItem("clientData"));
        if (userData?.token) {
          if (isApplicationJson) {
            sentHeaders = {
              Authorization: "Bearer " + userData.token,
              "Content-Type": "application/json",
              ...sentHeaders,
            };
          } else {
            sentHeaders = { Authorization: "Bearer " + userData.token, ...sentHeaders };
          }
        }
      }
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const response = await fetch(url, {
          method,
          body,
          headers: sentHeaders,
          signal: httpAbortCtrl.signal,
        });

        let responseData;
        if (!isResponseBlob) {
          responseData = await response.json();
        } else {
          responseData = await response.blob();
        }

        activeHttpRequests.current = activeHttpRequests.current.filter((reqCtrl) => reqCtrl !== httpAbortCtrl);
        if (!response.ok) {
          const error = new Error(responseData.message);
          error.code = response.status;
          throw error;
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
