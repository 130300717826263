import React, { useCallback, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import ItemHeader from "pages/shared/ItemHeader";
import { StoreContext } from "shared/context/store-context";
import { useForm } from "shared/hooks/form-hook";

import Help from "./Help";
import List from "./List";

import "./Layout.css";

const Layout = ({
  itemType,
  SelectedItemComponent,
  NewItemComponent,
  items,
  listColumns,
  listName,
  standardHelpItems,
  highlightedHelpItems,
  DeleteItem,
  InsertItem,
  UpdateItem,
  isAlwaysFullScreen,
  hideCreateNewItem,
  hideTopBarWhenSelected,
  showSelectedYear,
  hideRightHeader,
}) => {
  const { t } = useTranslation();

  const { educationYearsOptions, selectedEducationYearId, UpdateSelectedEducationYearId } = useContext(StoreContext);

  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [newItemShow, setNewItemShow] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(isAlwaysFullScreen ? true : false);
  const [isMobile, setIsMobile] = useState(true);

  const yearChangeHandler = (_id, value) => {
    if (value) {
      UpdateSelectedEducationYearId(value);
    }
  };

  const handleWindowSizeChange = useCallback(() => {
    if (window.innerWidth <= 1156) {
      setIsFullScreen(true);
      setIsMobile(true);
    } else {
      if (!isAlwaysFullScreen) {
        setIsFullScreen(false);
      }
      setIsMobile(false);
    }
  }, [isAlwaysFullScreen]);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  const ItemSelectHandler = useCallback(
    (itemid) => {
      setSelectedItemShow(true);
      setNewItemShow(false);
      setSelectedItemId(itemid);
      if (!isMobile && !isAlwaysFullScreen) {
        setIsFullScreen(false);
      }
    },
    [isMobile, isAlwaysFullScreen]
  );

  const newItemHandler = useCallback(() => {
    setSelectedItemShow(false);
    setNewItemShow(true);
  }, []);

  const closeRightItemHandler = useCallback(() => {
    setNewItemShow(false);
    setSelectedItemShow(false);
    setSelectedItemId(null);
    setSelectedItemId(null);
  }, []);

  const isHeaderHidden = hideCreateNewItem && !standardHelpItems && !highlightedHelpItems && !showSelectedYear;

  const showHeader =
    !hideCreateNewItem || standardHelpItems || highlightedHelpItems || (showSelectedYear && !selectedItemId);

  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      {showHeader && (
        <div className="layout-navigation">
          {!hideCreateNewItem && (
            <Button danger size="small" onClick={newItemHandler}>
              {t(`${itemType}.createNewItem`)}
            </Button>
          )}
          {showSelectedYear && !selectedItemId && (
            <div style={{ width: "200px", marginTop: "1rem" }}>
              <CustomSelect
                options={educationYearsOptions}
                id="year"
                onInput={(label, value) => yearChangeHandler(label, value)}
                //onInput={(id, value) => {}}
                label=""
                initialValid={true}
                initialValue={educationYearsOptions.find((year) => year.value === selectedEducationYearId)}
                //fireChange={(id, val) => yearChangeHandler(id, val)}
              ></CustomSelect>
            </div>
          )}
          {(standardHelpItems || highlightedHelpItems) && (
            <Help standardHelpItems={standardHelpItems} highlightedHelpItems={highlightedHelpItems} />
          )}
        </div>
      )}

      <div className="layout-container">
        {items && (
          <div
            className={isHeaderHidden ? "layout-list-container-without-header" : "layout-list-container"}
            style={{ display: isFullScreen && (selectedItemShow || newItemShow) ? "none" : "flex" }}
          >
            <List
              name={listName || itemType}
              items={items}
              columns={listColumns}
              onItemSelect={ItemSelectHandler}
              selectedId={selectedItemId}
            />
          </div>
        )}
        {((selectedItemId && selectedItemShow) || newItemShow) && (
          <div className={"layout-selected-item-container"}>
            {!hideRightHeader && (
              <div className="layout-right-header" style={isHeaderHidden ? { marginTop: "10px" } : {}}>
                <ItemHeader
                  isMobile={isMobile}
                  isAlwaysFullScreen={isAlwaysFullScreen}
                  isFullScreen={isFullScreen}
                  onFullScreen={() => setIsFullScreen(true)}
                  onHalfScreen={() => setIsFullScreen(false)}
                  onClose={closeRightItemHandler}
                />
              </div>
            )}
            {selectedItemId && selectedItemShow && (
              <React.Fragment>
                <SelectedItemComponent
                  key={selectedItemId}
                  itemId={selectedItemId}
                  onClose={closeRightItemHandler}
                  DeleteItem={DeleteItem}
                  UpdateItem={UpdateItem}
                ></SelectedItemComponent>
              </React.Fragment>
            )}
            {newItemShow && (
              <NewItemComponent
                isNewItem={true}
                gotoItem={ItemSelectHandler}
                onClose={closeRightItemHandler}
                InsertItem={InsertItem}
              ></NewItemComponent>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Layout;
