import React, { useEffect, useState, useContext, useCallback } from "react";

import { StoreContext } from "shared/context/store-context";

import NotFound from "shared/components/UIElements/NotFound";

import TransferAnalyzer from "./TransferAnalyzer";
import TransferInfo from "./TransferInfo";

import { getShiftDate } from "shared/util/util";

const ManagedTransfer = ({ itemId, onClose }) => {
  const { standartPersonelServiceOptions, transfers, standartPersonelServices, schools } = useContext(StoreContext);

  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [school, setSchool] = useState();
  const [currentTransfer, setCurrentTransfer] = useState();
  const [currentPersonelServices, setCurrentPersonelServices] = useState();
  const [currentPersonelServicesOptions, setCurrentPersonelServicesOptions] = useState();
  const [transferDisplayName, setTransferDisplayName] = useState();

  const ItemTypeTR = "Servis";

  const fetchItem = useCallback(async () => {
    try {
      const currentTransfer = transfers.find((transfer) => transfer.id === itemId);
      if (currentTransfer) {
        setCurrentTransfer(currentTransfer);
        setTransferDisplayName(currentTransfer.transferdate + ", " + currentTransfer.name);
        setSchool(schools.find((school) => school.id === currentTransfer.school));

        const currentServices = standartPersonelServices.filter((personelService) =>
          currentTransfer.vehicleRoutes.includes(personelService.id)
        );

        setCurrentPersonelServices(currentServices);

        setCurrentPersonelServicesOptions(
          currentServices.map((service) => {
            return {
              value: service.id,
              label: service.name,
            };
          }) || []
        );
      }
    } catch (err) {
      console.log(err);
    }
    setIsItemLoaded(true);
  }, [transfers, itemId, schools, standartPersonelServices]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  if (!isItemLoaded) {
    return <div></div>;
  }

  if (!currentTransfer) {
    return <NotFound item={ItemTypeTR}></NotFound>;
  }

  return (
    <React.Fragment>
      {currentTransfer && (
        <div>
          <TransferInfo transfer={currentTransfer} onClose={onClose} />
          <TransferAnalyzer
            school={school}
            transfer={currentTransfer}
            transfertime={currentTransfer.transfertime.split(" ")[4].substring(0, 5)}
            displayName={transferDisplayName}
            services={currentPersonelServices}
            servicesOptions={currentPersonelServicesOptions}
            onClose={onClose}
            // dropOffServices={currentShiftServicesGETOFF}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ManagedTransfer;
