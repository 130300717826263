import React, { useState, useContext } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";

import ThumbUp from "@mui/icons-material/ThumbUp";
import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import "./Admin.css";
import {
  getStudentsWithLocationProblems,
  getStudentsWithoutNotifications,
  getNotificationsWithoutParent,
} from "./utils";
import Students from "pages/Students";

const DataErrors = (props) => {
  const store = useContext(StoreContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [reportCreated, setReportCreated] = useState(false);

  const [studentsWithLocationProblems, setStudentsWithLocationProblems] = useState([]);
  const [studentsWithoutNotifications, setStudentsWithoutNotifications] = useState([]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
    },
    false
  );

  const createReport = async () => {
    let allActiveStudents = store.students
      .filter((s) => !s.hasOwnProperty("isActive") || s?.isActive)
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    let allNotifications = store.relations;

    let activeStudentsInSchool = [...allActiveStudents];
    if (formState.inputs.school.value.toString() !== "619c62eb5d123200169418a8") {
      activeStudentsInSchool = allActiveStudents.filter(
        (s) => s.school.toString() === formState.inputs.school.value.toString()
      );
    }

    const problematicNotifications = getNotificationsWithoutParent(
      activeStudentsInSchool,
      store.users,
      allNotifications
    );
    if (problematicNotifications.length > 0) {
      problematicNotifications.forEach(async (n) => {
        try {
          await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/relations/${n.id}`, "DELETE", null, {
            "Content-Type": "application/json",
          });
        } catch (err) {}
      });

      store.UpdateRelations();
      const problematicNotificationsIds = problematicNotifications.map((n) => n.id);
      allNotifications = allNotifications.filter((n) => !problematicNotificationsIds.includes(n.id));
    }

    setStudentsWithLocationProblems(getStudentsWithLocationProblems(activeStudentsInSchool));
    setStudentsWithoutNotifications(getStudentsWithoutNotifications(activeStudentsInSchool, allNotifications));
    setReportCreated(true);
  };

  // if(isLoading || !store.vehicleRoutes) //HERE
  if (isLoading && false) {
    //HERE
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
            padding: "10px",
          }}
          container
          spacing={1}
        >
          <Grid item xs={4}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              errorText="Kurum seçimini yapınız."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          <Grid item xs={3}>
            <div style={{ left: "10px", position: "relative", top: "8px" }}>
              <Button type="button" disabled={!formState.isValid} onClick={createReport}>
                Rapor Oluştur
              </Button>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div style={{ left: "10px", position: "relative", top: "8px" }}>
              Sadece aktif öğrencileriniz değerlendirmeye alınacaktır.
              <br />
            </div>
          </Grid>
        </Grid>
      </form>
      {reportCreated && (
        <div>
          <br />
          <div className="adminpageContainer">
            <div className="adminListContainer">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  {studentsWithLocationProblems.length > 0 && (
                    <div>
                      Konumu bulunmayan öğrenciler - <strong> {studentsWithLocationProblems.length} hata </strong>
                    </div>
                  )}

                  {studentsWithLocationProblems.length === 0 && (
                    <div style={{ display: "flex" }}>
                      <strong style={{ marginRight: "20px" }}>
                        {" "}
                        <ThumbUp />
                      </strong>{" "}
                      Tebrikler. Tüm öğrencilerinizin konumu işaretlenmiş durumda.
                    </div>
                  )}
                </AccordionSummary>
                {studentsWithLocationProblems.length > 0 && (
                  <AccordionDetails display="flex">
                    <Students students={studentsWithLocationProblems} />
                  </AccordionDetails>
                )}
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  {studentsWithoutNotifications.length > 0 && (
                    <div>
                      Bildirim eşleşmesi bulunmayan öğrenciler -{" "}
                      <strong> {studentsWithoutNotifications.length} hata </strong>
                    </div>
                  )}

                  {studentsWithoutNotifications.length === 0 && (
                    <div style={{ display: "flex" }}>
                      <strong style={{ marginRight: "20px" }}>
                        {" "}
                        <ThumbUp />
                      </strong>{" "}
                      Tebrikler. Tüm öğrencilerinizin bildirim eşleşmesi yapılmış.
                    </div>
                  )}
                </AccordionSummary>
                {studentsWithoutNotifications.length > 0 && (
                  <AccordionDetails display="flex">
                    {studentsWithoutNotifications.map((student) => (
                      <div style={{ width: "100%" }}>{student.name}</div>
                    ))}
                  </AccordionDetails>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DataErrors;
