import React from "react";

import Tabs from "components/shared/Tabs";
import AutomationsList from "components/admin/automation/AutomationsList";

const Admin = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let adminTabs = [];

  adminTabs.push({
    label: "Otomatik İşlemler",
    content: <AutomationsList />,
  });

  return (
    <React.Fragment>
      <Tabs tabs={adminTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />
    </React.Fragment>
  );
};

export default Admin;
