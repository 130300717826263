/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormFooter from "shared/components/FormElements/FormFooter";
import NotFound from "shared/components/UIElements/NotFound";

import Grid from "@mui/material/Grid";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import DeleteModal from "components/shared/DeleteModal";
import Modal from "shared/components/UIElements/Modal";
import ItemGenerated from "components/shared/ItemGenerated";
import { formatDate } from "shared/util/time";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_NONE } from "shared/util/validators";
import { isCompanyReceiptOptions, userApproved } from "shared/util/types";
import { parseComplexDate, CheckAuthorization } from "shared/util/util";
import { GetParentFromStudent } from "shared/util/relationaldata";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import { t } from "i18next";
// #endregion Hooks

const ContractItem = ({ contract, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [generatedItemId, setGeneratedItemId] = useState();
  const [isItemLoaded, setIsItemLoaded] = useState(false);

  const itemType = "contract";
  const endPointUpdate = "contracts";
  const endPointDelete = "contracts";
  const endPointCreate = "contracts";

  const [openSmsSend, setOpenSmsSend] = useState(false);

  const [loadedItem, setLoadedItem] = useState();

  const [userApprovedKey, setUserApprovedKey] = useState();

  const [payerNameKey, setPayerNameKey] = useState();
  const [payerIdentityKey, setPayerIdentityKey] = useState();
  const [payerMailKey, setPayerMailKey] = useState();
  const [payerAddressKey, setPayerAddressKey] = useState();
  const [isCompanyReceiptKey, setIsCompanyReceiptKey] = useState(false);
  const [taxNoKey, setTaxNoKey] = useState();
  const [taxOfficeKey, setTaxOfficeKey] = useState();
  const [companyTitleKey, setCompanyTitleKey] = useState();

  const [yearKey, setYearKey] = useState();
  const [dateKey, setDateKey] = useState(false);
  const [serviceStartDateKey, setServiceStartDateKey] = useState();
  const [serviceEndingDateKey, setServiceEndingDateKey] = useState();

  const [totalAmountKey, setTotalAmountKey] = useState();

  //const [isCompanyReceipt, setIsCompanyReceipt] = useState(false);

  const [finalAmount, setFinalAmount] = useState();
  const [finalAmountKey, setFinalAmountKey] = useState();

  const [phoneNumber, setPhoneNumber] = useState();

  // #region Standard Interface functions
  const { isLoading, error, sendRequest } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);

  const showSMSHandler = () => {
    if (
      formStatePhoneNumber.inputs.phonenumber.value.trim().length === 10 &&
      formStatePhoneNumber.inputs.phonenumber.value.trim().toString().charAt(0) == "5"
    ) {
      setShowSmsModal(true);
    }
  };

  const cancelSMSHandler = () => {
    setShowSmsModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    if (isNewItem) {
      setIsItemLoaded(true);
      setLoadedItem({});
      return;
    }
    setIsItemLoaded(false);

    try {
      // const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}/${itemId}`);

      const myuser = GetParentFromStudent(store.userMap, store.studentToParentMap, contract?.student?.id?.toString());

      if (myuser?.phonenumber) {
        setPhoneNumber(myuser.phonenumber);
      }

      setLoadedItem(contract);
    } catch (err) {}
    setIsItemLoaded(true);
  });

  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [contract]);

  const [formState, inputHandler, setFormData] = useForm(
    {
      payerName: { value: "", isValid: true },
      payerIdentity: { value: "", isValid: true },
      payerAddress: { value: "", isValid: true },
      payerMail: { value: "", isValid: true },
      student: { value: "", isValid: true },
      year: { value: "", isValid: true },
      date: { value: "", isValid: true },
      contractEnabled: { value: true, isValid: true },
      serviceTemplate: { value: "", isValid: true },
      serviceStartDate: { value: "", isValid: true },
      serviceEndingDate: { value: "", isValid: true },
      totalAmount: { value: "", isValid: true },
      finalAmount: { value: "", isValid: true },
      isCompanyReceipt: { value: false, isValid: true },
      taxNo: { value: "", isValid: true },
      taxOffice: { value: "", isValid: true },
      companyTitle: { value: "", isValid: true },
      standardDiscounts: { value: [], isValid: true },
      specialDiscount: { value: 0, isValid: true },
      specialDiscountDescription: { value: "", isValid: true },
      userApproved: { value: false, isValid: true },
    },
    true
  );

  const [formStatePhoneNumber, inputHandlerPhoneNumber, setFormDataPhoneNumber] = useForm(
    {
      phonenumber: { value: "", isValid: false },
    },
    false
  );

  const checkContractStatus = async (tey) => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}/${contract.id}`);
      const contractApprovedStatus = responseData[itemType].userApproved;
      setItemState("userApproved", contractApprovedStatus, true, setUserApprovedKey);
    } catch (err) {}
  };

  const sendContractApprovalLink = useCallback(async () => {
    setShowSmsModal(false);

    let message = `Öğrenci taşıma sözleşmeniz oluşturulmuştur. Lütfen Servistesin uygulaması üzerinden durak bilgilerinizi kontrol ediniz. Çetin Tur`;

    message = `Sözleşme onayınız için aşağıdaki bağlantıya tıklayınız. 
    https://filokar.com/gbvfdsrfdc?c=${loadedItem.id}`;

    // // const user = loadedItem.user.id.toString();
    // const user = "61e3c7a2cd698a001617f59e";
    // // const foundPhone = loadedItem.user.phonenumber;
    // const foundPhone = "5355802151";
    const phone = formStatePhoneNumber.inputs.phonenumber.value.trim();
    const date = formatDate(new Date());
    let text = message;
    let smsData = { user: parent, phone, text, date };

    // try {
    //   const messageResponse = await sendRequest(
    //     `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
    //     "POST",
    //     JSON.stringify(smsData),
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + auth.token,
    //     }
    //   );
    // } catch (err) {}

    // text = message;
    // smsData = { user: parent, phone, text, date };

    try {
      const messageResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
        "POST",
        JSON.stringify(smsData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}

    setOpenSmsSend(true);
  });

  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();

    let specialDiscountToWrite = 0;
    try {
      specialDiscountToWrite = parseInt(formState.inputs.specialDiscount.value.toString().trim());
    } catch (err) {
      specialDiscountToWrite = 0;
    }

    if (specialDiscountToWrite === null) {
      specialDiscountToWrite = 0;
    }

    try {
      const infoToBeSent = {
        payerName: formState.inputs.payerName.value,
        payerIdentity: formState.inputs.payerIdentity.value,
        payerAddress: formState.inputs.payerAddress.value,
        payerMail: formState.inputs.payerMail.value,
        student: formState.inputs.student.value,
        year: formState.inputs.year.value,
        date: formState.inputs.date.value,
        contractEnabled: formState.inputs.contractEnabled.value,
        serviceTemplate: formState.inputs.serviceTemplate.value,
        serviceStartDate: formState.inputs.serviceStartDate.value,
        serviceEndingDate: formState.inputs.serviceEndingDate.value,
        totalAmount: formState.inputs.totalAmount.value,
        isCompanyReceipt: formState.inputs.isCompanyReceipt.value,
        taxNo: formState.inputs.taxNo.value,
        taxOffice: formState.inputs.taxOffice.value,
        companyTitle: formState.inputs.companyTitle.value,
        standardDiscounts: formState.inputs.standardDiscounts.value,
        specialDiscount: specialDiscountToWrite,
        specialDiscountDescription: formState.inputs.specialDiscountDescription.value,
        userApproved: formState.inputs.userApproved.value,
        finalAmount: formState.inputs.finalAmount.value,
      };

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${contract.id}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      window.toast.error(t("actionFailed"));
    }
  });

  const applyServiceTemplate = useCallback(
    async (tey) => {
      const templateId = formState.inputs.serviceTemplate.value;
      const currentServiceTemplate = store.serviceTemplates.find(
        (serviceTemplate) => serviceTemplate.id === templateId
      );
      if (!currentServiceTemplate) {
        return;
      }

      setItemState("serviceStartDate", currentServiceTemplate.serviceStartDate, true, setServiceStartDateKey);
      setItemState("serviceEndingDate", currentServiceTemplate.serviceEndingDate, true, setServiceEndingDateKey);
      setItemState("year", currentServiceTemplate.year, true, setYearKey);

      const today = new Date();
      const date = parseComplexDate(today);
      setItemState("date", date, true, setDateKey);

      setItemState("totalAmount", currentServiceTemplate.amount, true, setTotalAmountKey);
    },
    [formState.inputs.serviceTemplate.value, store.serviceTemplates]
  );

  const calculateFinalAmount = useCallback(async () => {
    const totalAmountHere = parseFloat(formState.inputs.totalAmount.value);
    const specialDiscount = parseFloat(formState.inputs.specialDiscount.value);
    const standardDiscounts = formState.inputs.standardDiscounts.value;

    let calculatedAmount = totalAmountHere;

    standardDiscounts.forEach((discount) => {
      const currentDiscount = store.discounts.find((item) => item.id.toString() === discount.toString());

      //1 --- Percentage Discount
      if (currentDiscount.type === "1") {
        calculatedAmount = calculatedAmount - (calculatedAmount * currentDiscount.amount) / 100;
      }

      //2 --- Constant Discount
      if (currentDiscount.type === "2") {
        calculatedAmount = calculatedAmount - currentDiscount;
      }
    });

    if (specialDiscount) {
      calculatedAmount = calculatedAmount - specialDiscount;
    }

    setItemState("finalAmount", parseInt(calculatedAmount), true, setFinalAmountKey);
  }, [
    formState.inputs.totalAmount.value,
    formState.inputs.specialDiscount.value,
    formState.inputs.standardDiscounts.value,
  ]);

  const setItemState = (item, value, valid, setState) => {
    setState(item + "-" + Math.random());
    inputHandler(item, value, true);
    setLoadedItem((prevItem) => {
      return {
        ...prevItem,
        [item]: value,
      };
    });
  };

  const bringPreviousContractOwner = useCallback(async () => {
    const studentId = formState?.inputs?.student?.value;
    const currentStudent = store.students.find((student) => student.id === studentId);

    if (currentStudent) {
      const previousContract = store.contracts.find(
        (contract) => contract?.student?.id?.toString() === studentId.toString()
      );
      if (previousContract) {
        setItemState("payerName", previousContract.payerName, true, setPayerNameKey);
        setItemState("payerIdentity", previousContract.payerIdentity, true, setPayerIdentityKey);
        setItemState("payerAddress", previousContract.payerAddress, true, setPayerAddressKey);
        setItemState("payerMail", previousContract.payerMail, true, setPayerMailKey);
        setItemState("isCompanyReceipt", previousContract.isCompanyReceipt, true, setIsCompanyReceiptKey);
        setItemState("taxNo", previousContract.taxNo, true, setTaxNoKey);
        setItemState("taxOffice", previousContract.taxOffice, true, setTaxOfficeKey);
        setItemState("companyTitle", previousContract.companyTitle, true, setCompanyTitleKey);
        return;
      }
    }

    setItemState("payerName", "", true, setPayerNameKey);
    setItemState("payerIdentity", "", true, setPayerIdentityKey);
    setItemState("payerAddress", "", true, setPayerAddressKey);
    setItemState("payerMail", "", true, setPayerMailKey);
    setItemState("isCompanyReceipt", false, true, setIsCompanyReceiptKey);
    setItemState("taxNo", "", true, setTaxNoKey);
    setItemState("taxOffice", "", true, setTaxOfficeKey);
    setItemState("companyTitle", "", true, setCompanyTitleKey);
  }, [formState.inputs.student.value, store.contracts, store.students]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSmsSend(false);
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        // onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        // onClose={() => {
        //   setGeneratedItemId(false);
        //   onClose();
        // }}
      />
    );
  }

  return (
    <React.Fragment>
      {!isNewItem && (
        <DeleteModal
          endPoint={endPointDelete}
          itemType={itemType}
          itemId={contract.id}
          onDeleted={() => {
            DeleteItem(contract.id);
            onClose();
          }}
          onClose={() => setShowConfirmModal(false)}
          showModal={showConfirmModal}
        />
      )}

      <Modal
        show={showSmsModal}
        onCancel={cancelSMSHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelSMSHandler}>
              Hayır
            </Button>
            <Button danger onClick={sendContractApprovalLink}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>Kullanıcıya SMS ile sözleşme ve onay linki gönderilecek. Onaylıyor musunuz? Bu işlem geri alınamaz.</p>
        <p>
          Telefon numarası: <b>{formStatePhoneNumber.inputs.phonenumber.value.trim()}</b>
        </p>
      </Modal>
      {isItemLoaded && (
        <form className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="horizontal-flex" style={{ justifyContent: "flex-start", alignItems: "center" }}>
            <div style={{ width: "400px" }}>
              <CustomSelect
                options={store.serviceTemplateOptions}
                id="serviceTemplate"
                onInput={inputHandler}
                label={t("contractTemplate")}
                initialValid={true}
                initialValue={store.serviceTemplateOptions.find(
                  (item) => item.value.toString() === loadedItem?.serviceTemplate?.toString()
                )}
              ></CustomSelect>
            </div>

            <div style={{ marginTop: "15px" }}>
              <Button type="button" danger onClick={applyServiceTemplate.bind(this, "apply")}>
                {t("applyTemplate")}
              </Button>
            </div>
          </div>

          <div className="groupContainer-accounting">
            <CustomSelect
              options={store.studentOptions}
              id="student"
              onInput={inputHandler}
              label={t("student.title")}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={isNewItem ? false : true}
              initialValue={store.studentOptions.find(
                (item) => item.value.toString() === loadedItem?.student?.id?.toString()
              )}
              // fireChange={studentChangeHandler}
            ></CustomSelect>

            {isNewItem && (
              <div style={{ marginBottom: "15px" }}>
                <Button type="button" danger onClick={bringPreviousContractOwner}>
                  Önceki Kontrat Sahibini Getir (Varsa)
                </Button>
              </div>
            )}

            <div className="horizontal-flex">
              <Input
                key={payerNameKey}
                id="payerName"
                element="input"
                type="text"
                label={t("payerName")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerName?.toString() || ""}
                onInput={inputHandler}
              />

              <Input
                key={payerIdentityKey}
                id="payerIdentity"
                element="input"
                type="text"
                label={t("identityNumber")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerIdentity?.toString() || ""}
                onInput={inputHandler}
              />
            </div>

            <div className="horizontal-flex">
              <Input
                key={payerMailKey}
                id="payerMail"
                element="input"
                type="text"
                label={t("email")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerMail?.toString() || ""}
                onInput={inputHandler}
              />

              <Input
                key={payerAddressKey}
                id="payerAddress"
                element="input"
                type="text"
                label={t("invoiceAddress")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerAddress?.toString() || ""}
                onInput={inputHandler}
              />
            </div>

            <div style={{ width: "200px" }}>
              <CustomSelect
                key={isCompanyReceiptKey}
                options={isCompanyReceiptOptions}
                id="isCompanyReceipt"
                onInput={inputHandler}
                label={t("isCompanyInvoice")}
                initialValue={isCompanyReceiptOptions.find(
                  (option) => option.value.toString() === loadedItem?.isCompanyReceipt?.toString()
                )}
                initialValid={true}
              ></CustomSelect>
            </div>
            {formState?.inputs?.isCompanyReceipt?.value && (
              <div className="horizontal-flex">
                <Input
                  key={taxNoKey}
                  id="taxNo"
                  element="input"
                  type="text"
                  label={t("taxId")}
                  initialValue={loadedItem?.taxNo?.toString() || ""}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                  initialValid={true}
                />

                <Input
                  key={taxOfficeKey}
                  id="taxOffice"
                  element="input"
                  type="text"
                  label={t("taxOffice")}
                  initialValue={loadedItem?.taxOffice?.toString() || ""}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                  initialValid={true}
                />

                <Input
                  key={companyTitleKey}
                  id="companyTitle"
                  element="input"
                  type="text"
                  label={t("companyName")}
                  initialValue={loadedItem?.companyTitle?.toString() || ""}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                  initialValid={true}
                />
              </div>
            )}
          </div>

          <div className="groupContainer-accounting">
            <Grid
              sx={{
                padding: "16px",
                fontsize: 16,
                flexGrow: 1,
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                justifyContent: { xs: "flex-start", md: "flex-start" },
                columnGap: "32px",
                textAlign: "left",
                width: "100%",
                id: "report-receipts-container",
              }}
              container
              spacing={1}
            >
              <Grid xs={12} md={2}>
                {/* <div style={{ width: "300px", marginRight: "100px" }}> */}
                <CustomSelect
                  key={yearKey}
                  options={store.educationYearsOptions}
                  id="year"
                  onInput={inputHandler}
                  label={t("educationYear")}
                  initialValid={true}
                  initialValue={store.educationYearsOptions.find(
                    (item) => item.value.toString() === loadedItem?.year?.toString()
                  )}
                ></CustomSelect>
              </Grid>
              {/* </div> */}

              <Grid xs={12} md={2}>
                <DatePicker
                  key={dateKey}
                  id="date"
                  onInput={inputHandler}
                  label={t("contractDate")}
                  initialValid={true}
                  initialValue={loadedItem?.date?.toString()}
                ></DatePicker>
              </Grid>

              <Grid xs={12} md={2}>
                <DatePicker
                  key={serviceStartDateKey}
                  id="serviceStartDate"
                  onInput={inputHandler}
                  label={t("serviceStartDate")}
                  initialValid={true}
                  initialValue={loadedItem?.serviceStartDate?.toString()}
                ></DatePicker>
              </Grid>

              <Grid xs={12} md={2}>
                <DatePicker
                  key={serviceEndingDateKey}
                  id="serviceEndingDate"
                  onInput={inputHandler}
                  label={t("serviceEndDate")}
                  validators={[VALIDATOR_REQUIRE()]}
                  initialValid={true}
                  initialValue={loadedItem?.serviceEndingDate?.toString()}
                ></DatePicker>
              </Grid>
            </Grid>
          </div>

          <div className="groupContainer-accounting">
            <CustomSelect
              options={store.discountOptions}
              id="standardDiscounts"
              onInput={inputHandler}
              label={t("standardDiscounts")}
              initialValid={true}
              isMulti={true}
              initialValue={store.discountOptions.filter((option) =>
                loadedItem?.standardDiscounts?.includes(option.value)
              )}
            ></CustomSelect>

            <Input
              id="specialDiscount"
              element="input"
              type="text"
              label={t("specialDiscountAmount")}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem?.specialDiscount?.toString() || "0"}
              onInput={inputHandler}
            />

            <Input
              id="specialDiscountDescription"
              element="input"
              type="text"
              label={t("specialDiscountDescription")}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem?.specialDiscountDescription?.toString() || ""}
              onInput={inputHandler}
            />
          </div>

          {!isNewItem && (
            <div>
              <div className="horizontal-flex">
                <CustomSelect
                  key={userApprovedKey}
                  options={userApproved}
                  id="userApproved"
                  onInput={inputHandler}
                  label={t("userApprovedContract")}
                  initialValue={
                    userApproved.find((item) => item.value.toString() === loadedItem?.userApproved?.toString()) ||
                    userApproved[0]
                  }
                  validators={[VALIDATOR_REQUIRE()]}
                  initialValid={true}
                  disabled
                ></CustomSelect>

                <Input
                  key={phoneNumber}
                  id="phonenumber"
                  element="input"
                  type="text"
                  label={t("phone")}
                  validators={[VALIDATOR_PHONENUMBER(10)]}
                  errorText="5 ile başlayan 10 haneli telefon numarası giriniz."
                  initialValid={true}
                  onInput={inputHandlerPhoneNumber}
                  initialValue={
                    phoneNumber
                      ? phoneNumber
                      : "Öğrenci - Veli eşleşmesi bulunmadı. Bildirimler sekmesini tamamlayınız."
                  }
                />
              </div>

              <div className="button-container">
                <Button type="button" danger onClick={showSMSHandler} disabled={!phoneNumber}>
                  Kullanıcıya sözleşme onayı gönder
                </Button>

                <Button type="button" danger onClick={checkContractStatus}>
                  Sözleşme onayı durumunu güncelle
                </Button>
              </div>

              <Snackbar
                open={openSmsSend}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                  Kullanıcıya sms bilgilendirmesi ile sözleşme onayı gönderildi.
                </MuiAlert>
              </Snackbar>
            </div>
          )}
          <Input
            key={totalAmountKey}
            id="totalAmount"
            element="input"
            type="text"
            label="Standart Hizmet Ücreti"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Hizmet ücretini giriniz."
            onInput={inputHandler}
            initialValue={loadedItem?.totalAmount?.toString() || ""}
            initialValid={true}
          />

          <div style={{ marginBottom: "15px" }}>
            <Button type="button" danger onClick={calculateFinalAmount}>
              Ödenecek Ücreti Hesapla
            </Button>
          </div>

          <Input
            key={finalAmountKey}
            id="finalAmount"
            element="input"
            type="text"
            label="Ödenecek Toplam"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Üstteki hizmet ücretini giriniz."
            onInput={inputHandler}
            initialValid={Boolean(loadedItem?.finalAmount)}
            //initialValue={finalAmount}
            initialValue={loadedItem?.finalAmount?.toString() || ""}
            disabled={true}
          />

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !formState.inputs.student.value || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default ContractItem;
