import React, { useEffect, useContext } from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Tab from "@mui/material/Tab";
// import TabPanel from "shared/components/UIElements/TabPanel";
// import PropTypes from "prop-types";
// import Tabs from "components/shared/Tabs";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import { CheckAuthorization } from "shared/util/util";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

// import "./EmployeeItem.css";

import Transfers from "components/transfer-management/Transfers";
import TransferNew from "components/transfer-management/TransferNew";
import TransferNewExcel from "components/transfer-management/TransferNewBulk";
import TransferRegion from "components/transfer-management/TransferRegion";
import TransferReport from "components/transfer-management/TransferReport";

import Tabs from "components/shared/Tabs";

const TransferManagement = (props) => {
  const auth = useContext(AuthContext);
  const currentUserId = auth.userId;
  const store = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);

  //const [isLoading, setIsLoading] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    //store.UpdateContracts();
    //store.RetrieveAccountingUtils();
    // setIsLoading(false);
    if (currentUserId === "3" || currentUserId === "1000") {
      // store.UpdateContracts();
    }
  }, [currentUserId]);

  if (!auth.permissions.accessToTransfers) {
    return <div className="center">Transfer uygulaması erişiminiz bulunmuyor.</div>;
  }

  let transferManagementTabs = [];

  transferManagementTabs.push({
    label: "Aktif Transferler",
    content: <Transfers active={true} />,
  });

  transferManagementTabs.push({
    label: "Tüm Transferler",
    content: <Transfers active={false} />,
  });

  transferManagementTabs.push({
    label: "Transfer Dosyası Yükle",
    content: <TransferNewExcel />,
  });

  transferManagementTabs.push({
    label: "Yeni Transfer",
    content: <TransferNew />,
  });

  transferManagementTabs.push({
    label: "Raporlama",
    content: <TransferReport />,
  });

  transferManagementTabs.push({
    label: "Bölgeler",
    content: <TransferRegion />,
  });

  return (
    <React.Fragment>
      <Tabs tabs={transferManagementTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />
    </React.Fragment>
  );
};

export default TransferManagement;
