export const CURRENT_EDUCATION_YEAR_ID = "661c8d1e862b3f2c307b1276";
export const CURRENT_EDUCATION_YEAR_LABEL = "2024-2025";

export const USER_TYPES = {
  DRIVER: "50",
  SCHOOL_PARENT: "2",
  SOUTHWIND_PERSONEL: "2",
  PERSONEL: "10",
};

export const CLIENT_TYPES = {
  ADMIN: "admin", //me
  CLIENT: "client", // arzu
  OWNSCHOOLS: "ownschools", //me
  EDITOR: "editor", // kurum sorumlusu
  ACCOUNTANT: "accountant", //mine
  DEMO: "demo", // sadece demo doldurmak icin demo istendiginde
  DRIVER: "driver",
  PARENT: "parent",
  PERSONEL: "personel",
};

export const SERVICE_TYPES = {
  SCHOOL: "school",
  SOUTHWIND: "southwind",
  PERSONEL: "personel",
};

export const SCHOOL_TYPES = {
  SCHOOL: "1",
  PERSONEL: "2",
};

export const PERMISSION_TYPES = {
  ACCESS_TO_USERS: "accessToUsers",
  ACCESS_TO_STUDENTS: "accessToStudents",
  ACCESS_TO_NOTIFICATIONS: "accessToNotifications",
  ACCESS_TO_VEHICLE_ROUTES: "accessToVehicleRoutes",
  ACCESS_TO_STANDARD_PERSONEL_SERVICES: "accessToStandardPersonelServices",
  ACCESS_TO_SHIFT_PERSONEL_SERVICES: "accessToShiftPersonelServices",
  ACCESS_TO_SHIFTS: "accessToShifts",
  ACCESS_TO_STOPS: "accessToStops",
  ACCESS_TO_SHIFT_MANAGEMENT: "accessToShiftManagement",
  ACCESS_TO_DATA_ERRORS_SCHOOL: "accessToDataErrorsSchool",
  ACCESS_TO_BULK_PROCESSES: "accessToBulkProcesses",
  ACCESS_TO_DISTRIBUTE_STUDENTS: "accessToDistribute",
  ACCESS_TO_EXCEL_ANALYSIS: "accessToExcelAnalysis",
  ACCESS_TO_SERVICE_REPORTS: "accessToServiceReports",
  ACCESS_TO_ALL_DRIVERS_IN_THE_COMPANY: "accessToAllDriversInTheCompany",
  ACCESS_TO_ACCOUNTING: "accessToAccounting",
  ACCESS_TO_TRANSFERS: "accessToTransfers",
  ACCESS_TO_DRIVERS: "accessToDrivers",
  ACCESS_TO_IKARUS: "accessToIkarus",
  ACCESS_TO_LIVE: "accessToLive",
  ACCESS_TO_ANALYSIS: "accessToAnalysis",
  USE_DESCRIPTION: "useDescription",
  USE_CARD_ID: "useCardId",
  ACCESS_TO_ADMIN: "accessToAdmin",
};

export const COLORS = {
  PRIMARY: "#fbdd04", //sari
  SECONDARY: "#0b0c0b", // siyah
  TERTIARY: "#d2b903", //orta sari
  QUATERNARY: "#ff8c00",
  QUINARY: "#ffa07a",
  SENARY: "#ff69b4",
  SEPTENARY: "#ff1493",
  OCTONARY: "#ff00ff",
  NONARY: "#ff00ff",
  DENARY: "#ff00ff",
};

export const HTTP_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  GONE: 410,
  PAYLOAD_TOO_LARGE: 413,
  UNSUPPORTED_MEDIA_TYPE: 415,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  ITEM_NOT_FOUND: 430,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  EMAIL_NOT_VERIFIED: 510,
  INVALID_ORIGIN_POINT: 511,
  INVALID_DESTINATION_POINT: 512,
  DIRECTION_NOT_FOUND: 513,
  FILE_NOT_FOUND: 514,
  INVALID_INPUT: 515,
  NO_GENEL_LISTE_SHEET: 516,
  NOT_ACTIONABLE: 517,
  USER_HAS_RELATION: 601,
  USER_IN_A_SERVICE: 602,
  STUDENT_HAS_RELATION: 603,
  STUDENT_IN_A_SERVICE: 604,
  STUDENT_HAS_CONTRACT: 605,
  STUDENT_ALREADY_EXISTS: 606,
  RELATION_ALREADY_EXISTS: 607,
  INVALID_LOCATION: 608,
  SMS_NOT_SENT: 609,
  STOP_HAS_USER: 610,
  SAME_USER_WITH_SAME_PHONE: 611,
  MULTIPLE_SHEETS_NOT_ALLOWED_IN_EXCEL: 612,
  CONTRACT_HAS_RECEIPT: 613,
  CONTRACT_HAS_WITHDRAW: 614,
};
