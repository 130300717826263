import React, { useEffect, useState, useContext, useCallback } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ReceiptItem from "components/accounting/Receipts/ReceiptItem";

// #region Components
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
// #endregion Components

// #region Hooks
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import { getContractYearLabel } from "shared/util/relationaldata";

const ContractReceipts = ({ contract, InsertItem, UpdateItem, DeleteItem, onClose }) => {
  const auth = useContext(AuthContext);
  const { receipts, InsertReceipt, UpdateReceipt, DeleteReceipt } = useContext(StoreContext);

  const [loadedItem, setLoadedItem] = useState();

  const [currentReceipts, setCurrentReceipts] = useState();
  const [totalPayed, setTotalPayed] = useState();
  const [calculatedInvoices, setCalculatedInvoices] = useState();

  const ItemTypeTR = "Fatura";
  const ItemEndPointType = "contracts";

  const { isLoading, error, sendRequest } = useHttpClient();

  const fetchItem = useCallback(async () => {
    try {
      setLoadedItem(contract);
      debugger;

      const allreceipts = receipts.filter((item) => item.contract.id.toString() === contract.id);
      setCurrentReceipts(allreceipts);

      let totalPayed = 0;
      allreceipts.forEach((item) => {
        totalPayed += item.amount;
      });
      setTotalPayed(totalPayed);

      const totalAmount = contract.finalAmount;
      const toBeInvoiced = totalAmount;

      if (getContractYearLabel(contract.year) !== "2025-2026") {
        const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/faturas/${contract.id}`);
        const faturas = response.fatura;

        const months = ["Eylül", "Ekim", "Kasım", "Aralık", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs"];
        let monthAmounts = [];

        for (let index = 0; index < months.length; index++) {
          if (months[index] === "Eylül") {
            monthAmounts.push(faturas.september);
          }
          if (months[index] === "Ekim") {
            monthAmounts.push(faturas.october);
          }
          if (months[index] === "Kasım") {
            monthAmounts.push(faturas.november);
          }
          if (months[index] === "Aralık") {
            monthAmounts.push(faturas.december);
          }
          if (months[index] === "Ocak") {
            monthAmounts.push(faturas.january);
          }
          if (months[index] === "Şubat") {
            monthAmounts.push(faturas.february);
          }
          if (months[index] === "Mart") {
            monthAmounts.push(faturas.march);
          }
          if (months[index] === "Nisan") {
            monthAmounts.push(faturas.april);
          }
          if (months[index] === "Mayıs") {
            monthAmounts.push(faturas.may);
          }
        }

        if (toBeInvoiced > 0) {
          let calculatedInvoices = [];
          for (let index = 0; index < months.length; index++) {
            const invoice = {
              month: months[index],
              // amount: faturas.find(fatura => fatura.)(toBeInvoiced / months.length).toFixed(1),
              amount: monthAmounts[index].toFixed(1),
              id: months[index],
            };
            calculatedInvoices.push(invoice);
          }
          setCalculatedInvoices(calculatedInvoices);
        }
      } else {
      }
    } catch (err) {}
  }, [contract, receipts, sendRequest]);

  useEffect(() => {
    fetchItem();

    //setCalculatedInvoices();
  }, [contract, fetchItem]);

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const updateContractFinalAmount = async (event) => {
    event.preventDefault();

    let newAmount = null;
    try {
      newAmount = parseInt(document.getElementById("totalAmountToCalculate").value.trim());
    } catch (err) {
      //Alert user
      alert("Girilen tutar geçerli bir sayı değil. Güncelleme işlemi yapılmadı." + err.toString());
      return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${contract.id}`,
        "PATCH",
        JSON.stringify({
          finalAmount: newAmount,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      fetchItem();
    } catch (err) {}
  };

  const updateEverythingVisually = async () => {
    try {
      const allreceipts = receipts.filter((item) => item.contract.toString() === contract.id);
      setCurrentReceipts(allreceipts);
      //sum all receipts amount
      let totalPayed = 0;
      allreceipts.forEach((item) => {
        totalPayed += item.amount;
      });
      setTotalPayed(totalPayed);

      const totalAmount = document.getElementById("totalAmountToCalculate").value.trim();

      // const toBeInvoiced = totalAmount - totalPayed;
      const toBeInvoiced = parseFloat(totalAmount);

      //get current month

      const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/faturas/${contract.id}`);

      const faturas = response.fatura;

      const months = ["Eylül", "Ekim", "Kasım", "Aralık", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs"];

      let monthAmounts = [];

      for (let index1 = 0; index1 < months.length; index1++) {
        if (months[index1] === "Eylül") {
          monthAmounts.push(faturas.september);
        }
        if (months[index1] === "Ekim") {
          monthAmounts.push(faturas.october);
        }
        if (months[index1] === "Kasım") {
          monthAmounts.push(faturas.november);
        }
        if (months[index1] === "Aralık") {
          monthAmounts.push(faturas.december);
        }
        if (months[index1] === "Ocak") {
          monthAmounts.push(faturas.january);
        }
        if (months[index1] === "Şubat") {
          monthAmounts.push(faturas.february);
        }
        if (months[index1] === "Mart") {
          monthAmounts.push(faturas.march);
        }
        if (months[index1] === "Nisan") {
          monthAmounts.push(faturas.april);
        }
        if (months[index1] === "Mayıs") {
          monthAmounts.push(faturas.may);
        }
      }

      if (toBeInvoiced > 0) {
        let calculatedInvoices = [];
        for (let index = 0; index < months.length; index++) {
          const invoice = {
            month: months[index],
            // amount: (toBeInvoiced / (months.length-1)).toFixed(1),
            amount: monthAmounts[index].toFixed(1),
            id: months[index],
          };

          calculatedInvoices.push(invoice);
        }

        setCalculatedInvoices(calculatedInvoices);
      }
    } catch (err) {}
  };

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      {!isLoading && loadedItem && (
        <React.Fragment>
          <div className="item-form">
            <div className="horizontal-flex">
              <div style={{ flex: 1 }}>
                <h4 style={{ marginBottom: 0, marginLeft: "20px" }}>Yeni Tahsilat</h4>
                <div style={{ width: "600px" }}>
                  <ReceiptItem fixedContract={contract} isNewItem={true} InsertItem={InsertReceipt} />
                </div>
              </div>
              <div style={{ flex: 1, marginLeft: "400px" }}>
                <h4>Tahsilatlar</h4>
                <TableContainer component={Paper} sx={{ width: 400 }}>
                  <Table sx={{ justifyContent: "flex-start" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Tarih</TableCell>
                        <TableCell>Ödenen</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentReceipts.map((row) => (
                        <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.date}
                          </TableCell>
                          <TableCell>{row.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ContractReceipts;
