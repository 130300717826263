import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { StoreContext } from "shared/context/store-context";
import { getContractYearLabel } from "shared/util/relationaldata";

import ItemHeader from "pages/shared/ItemHeader";

const ContractInfo = ({ contract, paidAmount, leftAmount, onClose }) => {
  const { t } = useTranslation();

  const { schoolOptions, receipts } = useContext(StoreContext);

  return (
    <React.Fragment>
      {contract && (
        <div className="card horizontal-flex align-items-center justify-content-between" style={{ width: "100%" }}>
          <div className="horizontal-flex align-items-center justify-content-start" style={{ gap: "40px", flex: 10 }}>
            <h4 style={{ margin: "0px" }}>
              {schoolOptions.find((option) => option.value === contract?.student.school.toString()).label}
            </h4>
            <span>
              <strong>Dönem:</strong> {getContractYearLabel(contract?.year) || "-"}
            </span>
            <span>
              <strong>Öğrenci:</strong> {contract?.student?.name || "-"}
            </span>
            <span>
              <strong>Fatura:</strong> {contract?.payerName ? contract?.payerName.slice(0, 20) : "-"}
            </span>
            <span>
              <strong>Toplam:</strong> {contract?.finalAmount ? contract.finalAmount + " TL" : "-"}
            </span>
            <span>
              <strong>Ödenen:</strong> {paidAmount ? paidAmount + " TL" : "-"}
            </span>
            <span>
              <strong>Kalan:</strong> {leftAmount ? leftAmount + " TL" : "-"}
            </span>
          </div>
          <div style={{ width: "100%", justifyContent: "flex-end", display: "flex", flex: 1 }}>
            <ItemHeader isAlwaysFullScreen={true} onClose={onClose} onFullScreen={() => {}} onHalfScreen={() => {}} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ContractInfo;
