import React from "react";
import { useTranslation } from "react-i18next";

import Button from "shared/components/FormElements/Button";

import "./DeleteModal.css";

const ItemGenerated = ({ itemType, onGotoNewItem, onCreateNewItem, onClose }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "36px",
          marginTop: "50px",
        }}
      >
        <h4>{t(`${itemType}.itemAddedSuccessfully`)}</h4>
        {onGotoNewItem && <Button onClick={onGotoNewItem}> {t(`${itemType}.goToNewItem`)}</Button>}
        {onCreateNewItem && <Button onClick={onCreateNewItem}> {t(`${itemType}.createNewItem`)}</Button>}
        {onClose && (
          <Button danger onClick={onClose}>
            {t("close")}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default ItemGenerated;
