import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";

import { StoreContext } from "shared/context/store-context";
import { paymentMethods, paymentTypes, banks } from "shared/util/types";

import WithdrawItem from "./WithdrawItem";

const Receipts = (props) => {
  const { t } = useTranslation();
  const itemType = "withdraw";
  const listName = "main_withdraws";

  const { withdraws, selectedEducationYearId, DeleteWithdraw, UpdateWithdraw, InsertWithdraw } =
    useContext(StoreContext);
  debugger;

  const [pageItems, setPageItems] = useState();

  useEffect(() => {
    setPageItems(withdraws.filter((item) => item.contract.year === selectedEducationYearId));
  }, [selectedEducationYearId, withdraws]);

  const listColumns = [
    {
      field: "school",
      headerName: "Okul",
      width: 300,
      valueGetter: (params) => {
        try {
          return params.row?.contract?.student.school.name;
        } catch (e) {
          console.log(e);
          return "-";
        }
      },
    },

    {
      field: "contract",
      headerName: "Fatura İsmi - Öğrenci",
      width: 500,
      valueGetter: (params) => {
        try {
          return params?.row.contract.payerName + " - " + params.row.contract?.student?.name;
        } catch (e) {
          return "-";
        }
      },
    },

    { field: "amount", headerName: "Meblağ", width: 150 },

    {
      field: "date",
      headerName: "Ödeme Tarihi",
      width: 150,
    },
    {
      field: "paymentMethod",
      headerName: "Ödeme Yöntemi",
      width: 150,

      valueGetter: (params) => {
        try {
          return paymentMethods.find((item) => item.value.toString() === params.row.paymentMethod.toString())?.label;
        } catch (e) {
          return "";
        }
      },
    },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      showSelectedYear={true}
      isAlwaysFullScreen={false}
      hideCreateNewItem={false}
      hideRightHeader={false}
      // hideTopBarWhenSelected={true}
      SelectedItemComponent={WithdrawItem}
      NewItemComponent={WithdrawItem}
      DeleteItem={DeleteWithdraw}
      InsertItem={InsertWithdraw}
      UpdateItem={UpdateWithdraw}
    />
  );
};

export default Receipts;
