import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PersonelTab from "./tabs/Personel";
import ServiceTab from "./tabs/Service";
import AutomatedServicesTab from "./tabs/AutomatedServices";

import { StoreContext } from "shared/context/store-context";

import { useShiftBackend } from "shared/hooks/backend/shift-hook";
import { useFreeSms } from "shared/hooks/backend/free-sms-hook";

import { CheckTimeString } from "shared/util/time";
import Tabs from "components/shared/Tabs";
import { findUserInServices } from "shared/util/relationaldata";

const ShiftAnalyzer = ({ school, shift, shiftDate, shiftDisplayName, services }) => {
  const { t } = useTranslation();
  const { users, UpdatePersonelService, InsertPersonelService, DeletePersonelService, UpdateShift } =
    useContext(StoreContext);

  const [type, setType] = useState("100");

  const { updateUsersInTheShift, updateUsersInTheService, updateUsersDetailsInTheService, addService, deleteService } =
    useShiftBackend();

  const { sendFreeSms } = useFreeSms();

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [usersInTheShift, setUsersInTheShift] = useState(users.filter((user) => shift.users?.includes(user.id)) || []);

  const updateUsersInTheShiftHandler = React.useCallback(
    async (users) => {
      const newShift = await updateUsersInTheShift(shift.id, users);
      if (newShift) {
        UpdateShift(newShift);
        return true;
      }
      return false;
    },
    [updateUsersInTheShift, shift.id, UpdateShift]
  );

  const updateUsersInTheServiceHandler = React.useCallback(
    async (serviceId, users) => {
      if (!serviceId) {
        return false;
      }
      const newPersonelService = await updateUsersInTheService(serviceId, users);
      if (newPersonelService) {
        UpdatePersonelService(newPersonelService);

        return true;
      }
      return false;
    },
    [updateUsersInTheService, UpdatePersonelService]
  );

  const changeUserServiceHandler = React.useCallback(
    async (userId, serviceId) => {
      // Find the user in the current shift
      const user = usersInTheShift.find((u) => u.id === userId);
      if (!user) return false; // If user doesn't exist, exit early

      // Find the user's current service
      const currentUserService = findUserInServices(services, userId);

      // If the user is already assigned to the target service, return true
      if (currentUserService?.id === serviceId) return true;

      let success = false;

      // Remove the user from the current service
      if (currentUserService) {
        currentUserService.users = currentUserService.users.filter((u) => u.id !== userId);
        success = await updateUsersInTheServiceHandler(
          currentUserService.id,
          currentUserService.users.map((u) => u.id)
        );
        if (!success) return false; // Exit if the removal fails
      }

      // Add the user to the new service (if specified)
      if (serviceId) {
        const targetService = services.find((service) => service.id === serviceId);
        if (!targetService) return false; // If the target service doesn't exist, exit early

        targetService.users.push(user);
        success = await updateUsersInTheServiceHandler(
          targetService.id,
          targetService.users.map((u) => u.id)
        );
      } else {
        // If no target service is specified, the operation is successful
        success = true;
      }

      if (success) {
        window.toast.success(t("updateSuccess"));
      }
      return success;
    },
    [usersInTheShift, services, updateUsersInTheServiceHandler, t]
  );

  const addPersonelHandler = React.useCallback(
    async (personelId) => {
      if (!usersInTheShift.map((user) => user.id).includes(personelId)) {
        const success = await updateUsersInTheShiftHandler([...usersInTheShift.map((user) => user.id), personelId]);
        if (success) {
          setUsersInTheShift([...usersInTheShift, users.find((user) => user.id === personelId)]);
        }
      }
    },
    [usersInTheShift, users, updateUsersInTheShiftHandler]
  );

  const removePersonel = React.useCallback(
    async (personelId) => {
      const success1 = await changeUserServiceHandler(personelId, null);

      const sucess2 = await updateUsersInTheShiftHandler(
        usersInTheShift.map((user) => user.id).filter((id) => id !== personelId)
      );
      if (success1 && sucess2) {
        setUsersInTheShift(usersInTheShift.filter((user) => user.id !== personelId));
      }
    },
    [changeUserServiceHandler, updateUsersInTheShiftHandler, usersInTheShift]
  );

  const updateUsersDetailsInTheServiceHandler = React.useCallback(
    async (serviceId, usersdetails) => {
      if (!serviceId || !usersdetails || !usersdetails.length) {
        return;
      }

      const personelservice = await updateUsersDetailsInTheService(serviceId, usersdetails);

      if (personelservice) {
        UpdatePersonelService(personelservice);
        return true;
      }
      return false;
    },
    [updateUsersDetailsInTheService, UpdatePersonelService]
  );

  const changeUserTransferTimeHandler = React.useCallback(
    async (userId, transferTime) => {
      if (transferTime === null || transferTime === undefined) {
        return;
      }
      if (transferTime && transferTime.length) {
        if (!CheckTimeString(transferTime)) {
          window.toast.error("Lütfen geçerli bir saat giriniz. Örnek: 21:30");
          return;
        }
      }

      const user = usersInTheShift.find((user) => user.id === userId);
      const newServicesIntheShift = [...services];
      if (user) {
        const currentUserService = newServicesIntheShift.find((service) =>
          service.users?.map((user) => user.id).includes(userId)
        );
        if (currentUserService) {
          const currentUsersDetails = currentUserService.usersdetails;
          const currentUserDetails = currentUsersDetails.find((userdetail) => userdetail?.user === userId);
          const currentUserTransferTime = currentUserDetails?.transfertime;
          if (currentUserTransferTime && currentUserTransferTime === transferTime) {
            return;
          }
          if (transferTime) {
            if (!currentUserDetails) {
              currentUserService.usersdetails.push({ user: userId, transfertime: transferTime, smses: [] });
            } else if (currentUserDetails) {
              currentUserDetails.transfertime = transferTime;
            }
          } else if (
            (transferTime === null || transferTime === undefined || transferTime === "") &&
            currentUserTransferTime
          ) {
            if (!currentUserDetails) {
              currentUserService.usersdetails.push({ user: userId, transfertime: "", smses: [] });
            } else if (currentUserDetails) {
              currentUserDetails.transfertime = "";
            }
          } else {
            return;
          }

          const success = await updateUsersDetailsInTheServiceHandler(currentUserService.id, currentUsersDetails);
          if (success) {
            window.toast.success(t("updateSuccess"));
          }
        }
      }
    },
    [usersInTheShift, services, updateUsersDetailsInTheServiceHandler, t]
  );

  const sendSMSHandler = React.useCallback(
    async (service, userId, text) => {
      const user = findUserInServices(services, userId);
      if (!user) {
        return;
      }

      //const phone = user.phone;
      const phone = "5355802151";

      const sendSms = await sendFreeSms(phone, text);

      if (sendSms) {
        const currentUsersDetails = service.usersdetails;
        const currentUserDetails = currentUsersDetails.find((userdetail) => userdetail?.user === userId);
        if (!currentUserDetails) {
          service.usersdetails.push({ user: userId, transfertime: "", smses: [sendSms.id] });
        } else {
          currentUserDetails.smses.push(sendSms.id);
        }

        await updateUsersDetailsInTheServiceHandler(
          service.id,
          service.usersdetails.map((userdetail) => {
            if (userdetail.user === userId) {
              return currentUserDetails;
            }
            return userdetail;
          })
        );

        //setCurrentSmses([...currentSmses, sendSms]);
      }
    },
    [services, sendFreeSms, updateUsersDetailsInTheServiceHandler]
  );

  const addServiceHandler = React.useCallback(
    async (name, drivers, plate, starttime, stoptime, routeClientArrivalTime) => {
      const infoToBeSent = {
        school: school.id,
        type: type,
        date: shiftDate,
        shift: shift.id,
        users: [],
        isshift: true,
        status: 1,
        name: name,
        drivers: drivers,
        plate: plate,
        starttime: starttime,
        stoptime: stoptime,
        routeClientArrivalTime: routeClientArrivalTime || undefined,
      };
      const newPersonelService = await addService(infoToBeSent);
      if (newPersonelService) {
        InsertPersonelService(newPersonelService);
        window.toast.success(t("updateSuccess"));
      }
    },
    [school.id, type, shiftDate, shift.id, addService, InsertPersonelService, t]
  );

  const deleteServiceHandler = React.useCallback(
    async (serviceId) => {
      const success = await deleteService(serviceId);
      if (success) {
        DeletePersonelService(serviceId);
      }
    },
    [deleteService, DeletePersonelService]
  );

  const servicesCreatedHandler = React.useCallback(() => {
    //I want to change the selected tab here
    window.toast.success("Servisler oluşturuldu");
    setTabValue(1);
  }, []);

  const shiftAnalyzerTabs = React.useMemo(() => {
    const personelTab = (
      <PersonelTab
        users={usersInTheShift}
        services={services}
        // shift={shift}
        shiftDate={shiftDate}
        shiftDisplayName={shiftDisplayName}
        onAddPersonel={addPersonelHandler}
        onRemovePersonel={removePersonel}
        onChangeUserService={changeUserServiceHandler}
        onChangeUserTransferTime={changeUserTransferTimeHandler}
        onSendSms={sendSMSHandler}
      ></PersonelTab>
    );

    const serviceTab = (
      <ServiceTab
        school={school}
        type={type}
        users={usersInTheShift}
        services={services}
        shift={shift}
        shiftDate={shiftDate}
        shiftDisplayName={shiftDisplayName}
        changeUserService={changeUserServiceHandler}
        addPersonel={addPersonelHandler}
        removePersonel={removePersonel}
        addService={addServiceHandler}
        deleteService={deleteServiceHandler}
      ></ServiceTab>
    );

    const automatedServicesTab = (
      <AutomatedServicesTab
        school={school}
        type={type}
        users={usersInTheShift}
        shift={shift}
        shiftDate={shiftDate}
        onServicesCreated={servicesCreatedHandler}
      />
    );

    return [
      {
        label: type === "100" ? "Vardiya Girişi Personelleri" : "Vardiya Çıkışı Personelleri",
        content: personelTab,
      },
      { label: type === "100" ? "Vardiya Girişi Servisleri" : "Vardiya Çıkışı Servisleri", content: serviceTab },
      { label: "Otomatik Servis İşlemleri", content: automatedServicesTab },
    ];
  }, [
    usersInTheShift,
    services,
    shiftDate,
    shiftDisplayName,
    addPersonelHandler,
    removePersonel,
    changeUserServiceHandler,
    changeUserTransferTimeHandler,
    sendSMSHandler,
    school,
    type,
    shift,
    addServiceHandler,
    deleteServiceHandler,
    servicesCreatedHandler,
  ]);

  return (
    <React.Fragment>
      <Tabs tabs={shiftAnalyzerTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={false} />
    </React.Fragment>
  );
};

export default ShiftAnalyzer;
