import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormFooter from "shared/components/FormElements/FormFooter";
import DeleteModal from "components/shared/DeleteModal";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";
import DatePicker from "shared/components/FormElements/DatePicker";
import { formatDate } from "shared/util/time";

// #endregion Components

// #region Utils
import { transfertypes, transferStatus } from "shared/util/types";
import { VALIDATOR_REQUIRE } from "shared/util/validators";

import { GetStartStopTime, CheckTimeString } from "shared/util/time";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { WindowSharp } from "@mui/icons-material";
// #endregion Hooks

const Transfer = ({ transfer, onClose }) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [showAlert, setShowAlert] = useState(false);
  const [loadedItem, setLoadedItem] = useState();

  const [view, setView] = useState(1);
  const alertMessageRef = useRef("");

  const ItemTypeTR = "Transfer";
  const ItemType = "transfer";
  const ItemEndPointType = "transfers";
  const endPointDelete = "transfers";

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      name: { value: "", isValid: true },
      type: { value: "", isValid: true },
      transferdate: { value: "", isValid: true },
      transfertime: { value: "", isValid: true },
      done: { value: false, isValid: true },
      vehicleRoutes: { value: [], isValid: true },
    },
    true
  );

  useEffect(() => {}, [formState.inputs.personel]);

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();

      if (!CheckTimeString(formState.inputs.transfertime.value)) {
        alertMessageRef.current =
          "Havalimanında olma saati bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
        setShowAlert(true);
        return;
      }

      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${transfer.id}`,
          "PATCH",
          JSON.stringify({
            name: formState.inputs.name.value,
            type: formState.inputs.type.value,
            school: formState.inputs.school.value,
            transferdate: formState.inputs.transferdate.value,
            transfertime: formState.inputs.transfertime.value,
            done: formState.inputs.done.value,
            //vehicleRoutes: currentVehicleRoutes,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        store.UpdateTransfer(responseData[ItemType]);
        window.toast.success(t("updateSuccess"));
      } catch (err) {
        window.toast.error(t("actionFailed"));
      }
    },
    [
      formState.inputs.transfertime.value,
      formState.inputs.name.value,
      formState.inputs.type.value,
      formState.inputs.school.value,
      formState.inputs.transferdate.value,
      formState.inputs.done.value,
      sendRequest,
      transfer.id,
      auth.token,
      store,
      t,
    ]
  );

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <DeleteModal
        endPoint={endPointDelete}
        itemType={ItemType}
        itemId={transfer.id}
        onDeleted={async () => {
          store.DeleteTransfer(transfer.id);

          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {transfer && (
        <div>
          <form className="item-form" onSubmit={itemUpdateSubmitHandler}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              initialValue={store.schoolOptions.find(
                (school) => school.value.toString() === transfer.school.toString()
              )}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>

            <Input
              id="name"
              element="input"
              type="text"
              label="Transfer İsmi"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Transfer ismi giriniz."
              onInput={inputHandler}
              initialValue={transfer.name}
              initialValid={true}
            />

            <CustomSelect
              options={transfertypes}
              id="type"
              onInput={inputHandler}
              label="Transfer Tipi"
              initialValue={transfertypes.find((type) => type.value.toString() === transfer.type.toString())}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>

            <DatePicker
              id="transferdate"
              onInput={inputHandler}
              label="Transfer Tarihi"
              initialValue={transfer.transferdate}
              initialValid={true}
            ></DatePicker>

            <Input
              id="transfertime"
              element="input"
              type="text"
              label="Havalimanında olma saati"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
              onInput={inputHandler}
              initialValue={GetStartStopTime(transfer.transfertime)}
              initialValid={true}
            />

            <CustomSelect
              options={transferStatus}
              id="done"
              onInput={inputHandler}
              label="İş Durumu"
              initialValue={transferStatus.find((status) => status.value.toString() === transfer.done.toString())}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>

            <FormFooter
              isNewItem={false}
              onUpdate={itemUpdateSubmitHandler}
              onDelete={() => setShowConfirmModal(true)}
              isUpdating={isLoading}
              disabled={!formState.isValid || !formState.inputs.school.value || isLoading}
            />
          </form>
        </div>
      )}
    </React.Fragment>
  );
};

export default Transfer;
