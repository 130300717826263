import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
import NotFound from "shared/components/UIElements/NotFound";

import MapWithTransferTimesContainer from "components/shared/MapWithTransferTimesContainer";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import VehicleRouteSms from "components/shared/VehicleRouteSms";
// #endregion Components

import { isArray } from "lodash";

// #region Utils
import { vehicleroutetypes, yesNoOptions } from "shared/util/types";
// import { GetPhoneNumberofStudent, GetParentFromStudent } from "shared/util/relationaldata";
import { VALIDATOR_REQUIRE, VALIDATOR_TIME_EMPTY_ACCEPTED } from "shared/util/validators";

import { GetStartStopTime } from "shared/util/time";
import { CheckTimeString } from "shared/util/time";
import { isPickUp } from "shared/util/util";
import { useBackend } from "shared/hooks/backend-hook";
// #endregion Utils

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

const PersonelServiceItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const auth = useContext(AuthContext);
  const {
    standartPersonelServices,
    users,
    schoolOptions,
    driverOptions,
    userOptions,
    schools,
    drivers,
    GetParentFromStudent,
  } = useContext(StoreContext);
  const { sendSMS, getSMSByVehicleRoute } = useBackend();

  // item info
  const itemType = "personelservice";
  const endPointUpdate = "personelservices";
  const endPointDelete = "personelservices";
  const endPointCreate = "personelservices";

  // item special states
  const [currentUsers, setCurrentUsers] = useState([]);
  const [view, setView] = useState(1);
  const [currentSmses, setCurrentSmses] = useState([]);
  const [currentDrivers, setCurrentDrivers] = useState([]);

  // item special functions
  // const loadSMS = useCallback(async () => {
  //   setCurrentSmses(await getSMSByVehicleRoute(itemId));
  // }, [getSMSByVehicleRoute, itemId]);

  const updateCurrentUsers = (data) => {
    if (!data) {
      setCurrentUsers([]);
      return;
    }

    // const nowUsers = users.filter((item) =>
    //   isArray(data) ? data.includes(item.id) : formState.inputs.users.value.includes(item.id)
    // );

    let nowUsers = [];

    let checker = isArray(data) ? data : formState.inputs.users.value;

    for (let i = 0; i < checker.length; i++) {
      const userId = checker[i];
      const user = users.find((item) => item.id === userId);
      if (!nowUsers.includes(user)) {
        nowUsers.push(user);
      }
    }

    const newUsers = [];

    const currentUsersIds = currentUsers.map((item) => item.id);

    for (let i = 0; i < nowUsers.length; i++) {
      const user = nowUsers[i];

      if (currentUsersIds.includes(user.id)) {
        user.transferTime = currentUsers.find((item) => item.id === user.id).transferTime;
      } else {
        if (loadedItem?.usersdetails && loadedItem?.usersdetails?.length > 0) {
          const currentusersdetails = loadedItem?.usersdetails?.find((item) => item?.user === user.id);
          if (currentusersdetails) {
            user.transferTime = currentusersdetails.transfertime;
          }
        }
      }
      newUsers.push(user);
    }

    //sort users by transfer time
    // newUsers.sort((a, b) => {
    //   if (a.transferTime && b.transferTime) {
    //     return a.transferTime.localeCompare(b.transferTime);
    //   } else if (a.transferTime) {
    //     return -1;
    //   } else if (b.transferTime) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });

    setCurrentUsers(newUsers);
  };

  const GotoView = (view) => {
    setView(view);
  };

  const updateDrivers = (updatedDrivers) => {
    setCurrentDrivers(
      drivers
        .filter((item) => updatedDrivers?.includes(item.id.toString()))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            phonenumber: item.phonenumber,
          };
        })
    );
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: schoolOptions.length > 1 ? "" : schoolOptions[0].value, isValid: true },
      type: { value: "", isValid: true },
      name: { value: null, isValid: true },
      drivers: { value: [], isValid: true },
      plate: { value: "", isValid: true },
      starttime: { value: null, isValid: true },
      stoptime: { value: null, isValid: true },
      users: { value: [], isValid: true },
      transferStartFinishTime: { value: null, isValid: true },
      useUsersDetails: { value: false, isValid: true },
    },
    true
  );

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentVehicleRoute = standartPersonelServices.find((vehicleRoute) => vehicleRoute.id === itemId);
      setLoadedItem(currentVehicleRoute);

      if (!currentVehicleRoute) {
        setIsItemLoaded(true);
        return;
      }
      setCurrentDrivers(
        drivers
          .filter((item) =>
            currentVehicleRoute?.drivers.map((driverItem) => driverItem.id.toString()).includes(item.id.toString())
          )
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              phonenumber: item.phonenumber,
            };
          })
      );

      //if currentVehicleRoute has array in usersdetaails. Each userdetail has a field of user (as id) and transfertime please setCurrentUsers with this information

      // if (currentVehicleRoute?.usersdetails?.length > 0) {
      //   let usersdetails = currentVehicleRoute.usersdetails;

      //   let sortedUserIds = [];

      //   for (let i = 0; i < usersdetails.length; i++) {
      //     sortedUserIds.push(usersdetails[i].user);
      //   }

      //   let sortedUsers = [];

      //   for (let i = 0; i < sortedUserIds.length; i++) {
      //     sortedUsers.push(users.find((user) => user.id === sortedUserIds[i]));
      //   }

      //   let sortedUsersWithTransferTime = [];

      //   for (let i = 0; i < sortedUsers.length; i++) {
      //     let user = sortedUsers[i];
      //     let transferTime = usersdetails.find((userdetail) => userdetail.user === user.id).transfertime;
      //     user.transferTime = transferTime;
      //     sortedUsersWithTransferTime.push(user);
      //   }

      //   setCurrentUsers(sortedUsersWithTransferTime);
      // } else {
      setCurrentUsers(
        currentVehicleRoute.users.map((user) => ({
          ...user,
          transferTime:
            currentVehicleRoute.usersdetails.find((order) => order?.user?.toString() === user?.id?.toString())
              ?.transfertime || null,
        }))
      );
      //}

      // loadSMS();
    } catch (err) {}
    setIsItemLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, standartPersonelServices, users, users]);

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // eslint-disable-next-line
  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();

    // if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
    //   alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
    //   setShowAlert(true);
    //   return;
    // }

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    let usersdetails = [];

    for (let i = 0; i < currentUsers.length; i++) {
      if (currentUsers[i].transferTime) {
        usersdetails.push({
          user: currentUsers[i].id,
          transfertime: currentUsers[i].transferTime,
        });
      }
    }

    const infoToBeSent = {
      name: formState.inputs.name.value,
      type: formState.inputs.type.value,
      school: formState.inputs.school.value,
      drivers: formState.inputs.drivers.value,
      plate: formState.inputs.plate.value,
      starttime: formState.inputs.starttime.value || "00:00",
      stoptime: formState.inputs.stoptime.value || "23:59",
      users: currentUsers.map((user) => user.id),
      routeClientArrivalTime: CheckTimeString(formState.inputs.transferStartFinishTime.value)
        ? formState.inputs.transferStartFinishTime.value
        : undefined,
      usersdetails: usersdetails,
      useUsersDetails: formState.inputs.useUsersDetails.value,
    };

    //standard code
    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  });

  const memoizedStops = useMemo(() => {
    return currentUsers.map((user) => ({ ...user }));
  }, [currentUsers]);

  //what is the above problem fix it

  const memoizedOrigin = useMemo(() => {
    return schools.find((school) => formState.inputs.school.value === school.id);
  }, [schools, formState.inputs.school.value]);

  const memoizedTransferStartFinishTime = useMemo(() => {
    const check = CheckTimeString(formState.inputs.transferStartFinishTime.value);
    if (!check) {
      return null;
    }
    return formState.inputs.transferStartFinishTime.value;
  }, [formState.inputs.transferStartFinishTime.value]);

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container">
          {!isNewItem && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* {view === 1 && loadedItem && (
                <Button type="button" size="small" danger onClick={() => GotoView(2)}>
                  {t("smsManagement")} &#62;
                </Button>
              )} */}

              {view === 2 && (
                <Button type="button" size="small" danger onClick={() => GotoView(1)}>
                  {t("serviceManagement")} &#62;
                </Button>
              )}
            </div>
          )}

          {view === 1 && (
            <div>
              <div className="vertical-flex">
                <div className="horizontal-flex">
                  {schoolOptions.length > 1 && (
                    <CustomSelect
                      options={schoolOptions}
                      id="school"
                      onInput={inputHandler}
                      label={t("client")}
                      initialValue={schoolOptions.find((option) => option.value === loadedItem?.school?.id)}
                      initialValid={isNewItem ? false : true}
                    ></CustomSelect>
                  )}

                  <Input
                    id="name"
                    element="input"
                    type="text"
                    label={t("name")}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText={t("requireField", { item: t("name") })}
                    initialValue={loadedItem?.name}
                    initialValid={isNewItem ? false : true}
                    onInput={inputHandler}
                  />

                  <CustomSelect
                    options={vehicleroutetypes}
                    id="type"
                    onInput={inputHandler}
                    label={t("routeType")}
                    initialValue={
                      vehicleroutetypes.find((option) => option.value.toString() === loadedItem?.type?.toString()) ||
                      vehicleroutetypes[0]
                    }
                    initialValid={true}
                  ></CustomSelect>

                  <Input
                    id="plate"
                    element="input"
                    type="text"
                    label={t("plateForRoute")}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText={t("requireField", { item: t("plate") })}
                    onInput={inputHandler}
                    initialValue={loadedItem?.plate}
                    initialValid={isNewItem ? false : true}
                  />

                  <Input
                    id="transferStartFinishTime"
                    element="input"
                    type="text"
                    label={
                      isPickUp(formState.inputs.type.value)
                        ? t("pickUpRouteClientArrivalTime")
                        : t("dropOffRouteClientArrivalTime")
                    }
                    validators={[VALIDATOR_TIME_EMPTY_ACCEPTED()]}
                    errorText={t("invalidTimeField", {
                      item: isPickUp(formState.inputs.type.value)
                        ? t("pickUpRouteClientArrivalTime")
                        : t("dropOffRouteClientArrivalTime"),
                    })}
                    onInput={inputHandler}
                    initialValue={
                      CheckTimeString(loadedItem?.routeClientArrivalTime) ? loadedItem?.routeClientArrivalTime : ""
                    }
                    initialValid={true}
                  />

                  <CustomSelect
                    options={yesNoOptions}
                    id="useUsersDetails"
                    onInput={inputHandler}
                    initialValue={
                      yesNoOptions.find((option) => option.value === loadedItem?.useUsersDetails) || yesNoOptions[1]
                    }
                    initialValid={true}
                    label="Yolculara saati göster"
                  ></CustomSelect>
                </div>

                <div className="horizontal-flex">
                  <div style={{ flex: 1 }}>
                    <CustomSelect
                      options={driverOptions}
                      id="drivers"
                      isMulti={true}
                      onInput={inputHandler}
                      label={t("driversForRoute")}
                      initialValue={driverOptions.filter((option) =>
                        currentDrivers.map((driver) => driver.id).includes(option.value)
                      )}
                      initialValid={true}
                      fireChange={(val) => updateDrivers(val)}
                    ></CustomSelect>
                  </div>
                  <div style={{ flex: 4 }}>
                    <CustomSelect
                      options={userOptions}
                      id="users"
                      isMulti={true}
                      onInput={inputHandler}
                      label={t("passengersIntheRoute")}
                      initialValue={currentUsers.map((user) => ({ value: user.id, label: user.name }))}
                      // currentUsers?.length
                      //   ? userOptions.filter((filteredUser) =>
                      //       currentUsers?.map((user) => user.id).includes(filteredUser.value)
                      //     )
                      //   : []

                      initialValid={true}
                      disabled={!formState.inputs.school.value}
                      fireChange={(val) => updateCurrentUsers(val)}
                    ></CustomSelect>
                  </div>
                </div>
              </div>

              {currentUsers.length > 0 && (
                <React.Fragment>
                  <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
                    <MapWithTransferTimesContainer
                      stops={memoizedStops}
                      origin={memoizedOrigin}
                      type={formState.inputs.type.value}
                      enablePolyline={true}
                      transferStartFinishTime={memoizedTransferStartFinishTime}
                      isFullScreen={true}
                      onOptimize={(newStops) => {
                        setCurrentUsers(newStops);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}

              <FormFooter
                isNewItem={isNewItem}
                onUpdate={itemUpdateSubmitHandler}
                onDelete={() => setShowConfirmModal(true)}
                isUpdating={isLoading}
                disabled={!formState.inputs.school.value || !formState.isValid || isLoading}
              />
            </div>
          )}

          {/* {view === 2 && (
            <VehicleRouteSms students={currentUsers} drivers={currentDrivers} vehicleRouteId={itemId} />
          )} */}
        </form>
      )}
    </React.Fragment>
  );
};

export default PersonelServiceItem;
