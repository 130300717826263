import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";

import AutomationItem from "./AutomationItem";

const Automations = () => {
  const { t } = useTranslation();
  const itemType = "automation";
  const listName = "main_automations";

  const { automations, DeleteAutomation, InsertAutomation, UpdateAutomation } = useContext(StoreContext);
  const pageItems = automations;

  let listColumns = [];

  listColumns.push({
    field: "name",
    headerName: t("name"),
    width: 150,
  });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={AutomationItem}
      NewItemComponent={AutomationItem}
      DeleteItem={DeleteAutomation}
      InsertItem={InsertAutomation}
      UpdateItem={UpdateAutomation}
    />
  );
};

export default Automations;
