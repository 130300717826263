import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "shared/components/UIElements/TabPanel";

const VerticalTabs = ({ tabs, tabValue, onTabChange, isVertical }) => {
  const a11yProps = (index) => ({
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isVertical ? "row" : "column",
      }}
    >
      <Tabs
        orientation={isVertical ? "vertical" : "horizontal"}
        variant="scrollable"
        value={tabValue}
        onChange={onTabChange}
        sx={{ borderRight: 1, borderColor: "divider", width: isVertical ? "200px" : "100%" }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} {...a11yProps(index)} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={tabValue}
          index={index}
          style={{ padding: isVertical ? "0px" : "0px", borderRadius: isVertical ? "0px" : "0px", width: "100%" }}
        >
          {/* <div style={{ padding: "10px" }}>{tab.content}</div> */}
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};

VerticalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  tabValue: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default VerticalTabs;
